.portfolio {
	background: var(--Ghost-White, #F2F2F7);
	height: 100%;
}
.setting {
	width: 44px;
	height: 44px;
	display: inline-block;
	border: 2px solid black;
	border-radius: 50%;
	text-align: center;
	margin: 30px 0 0 30px;
	cursor: pointer;
}
.setting svg {
	display: inline-block;
	padding-top: 10px;
}
.editProfile {
	border-radius: 22px;
	border: 2px solid #000;
	margin: 30px 30px 0 0;
	float: right;
	padding: 8px 14px;
	color: #000;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	cursor: pointer;
}
.editProfile svg {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
}
.portfolioContainer {
	padding: 30px;
}
.topContainer img {
	border-radius: 50%;
	width: 160px;
	height: 160px;
}
.nameAndHandle {
	display: inline-block;
	vertical-align: top;
	margin-left: 20px;
	max-width: calc(100% - 180px);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.nameAndHandle h2 {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--bodyl);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 20px 0 10px;
}
.nameAndHandle label {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.topContainer p {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 25px 0 30px;
}
.vibes {
	border-radius: 24px;
	background: #E4D4BF;
	position: relative;
	padding: 20px 12px;
	overflow: hidden;
}
.userVibesCarousel {
	width: 50%;
	height: 150px;
	float: left;
}
.vibesTitleAndDesc {
    width: calc(50% - 10px);
    float: right;
    padding-right: 10px;
}
.vibesTitleAndDesc h3 {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--bodyl);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 0 0 5px;
}
.vibesTitleAndDesc p {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}
.leftNavigation {
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
    z-index: 3;
    cursor: pointer;
}
.rightNavigation {
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    z-index: 3;
    cursor: pointer;
}
.bucketListContainer {
	margin-top: 60px;
}
.tabContainer {
	text-align: center;
	position: relative;
}
.tabContainer span {
	border-bottom: 2px solid #1C1C1E;
	position: absolute;
	height: 2px;
	width: 140px;
	top: 25px;
	left: calc((100% - 280px) / 2);
	-webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.tabBtn {
	color: #000;
	text-align: center;
	font-family: var(--bodyl);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	width: 140px;
	border: none;
	outline: none;
	background: var(--Ghost-White, #F2F2F7);
	cursor: pointer;
}
.bucketList {
	padding: 0;
	min-height: 200px;
	overflow: hidden;
}
.bucketListItem {
	list-style: none;
	width: 31%;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 8px;
	float: left;
	cursor: pointer;
}
.bucketList li {
	margin-left: 2%;
}
.bucketListItem label {
 	color: #000;
	text-align: center;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin-top: 5px;
	display: block;
 }
.bucketListItem div {
	border-radius: 12px;
}
.customBLWrap {
	padding: 11px 0;
	background: #E4D4BF;
	min-height: 127px;
	cursor: pointer;
}
.bucketListItem h4 {
	color: #000;
	font-family: var(--bodyl);
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 5px 0 0;
	text-align: left;
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.emptyCompletedBucket {
	color: #000;
    text-align: center;
    font-family: var(--font-montserrat);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    list-style: none;
    width: 80%;
    margin: 60px auto 0 auto !important;
}
.blImage {
	width: 100%;
	min-height: 145px;
	border-radius: 12px;
	aspect-ratio: 0.76;
}
@media screen and (min-width: 913px) {
  	.portfolio,
  	.portfolioContainer,
  	.tabBtn {
    	background: #fff;
  	}
  	.customBLWrap {
  		min-height: 152px;
  	}
}

