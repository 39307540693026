.portfolioSetting {
	background: var(--Ghost-White, #F2F2F7);
	height: 100%;
}
.portfolioSettingContainer {
	padding: 30px;
	clear: both;
	overflow: hidden;
}
.reportAnIssue {
	color: #000;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	margin-top: 80px;
	display: block;
}
.pageHeading {
	color: #000;
	text-align: center;
	font-family: var(--bodyl);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	display: block;
	margin: 0;
}
.editProfile {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 50px 0 15px;
	display: block;
}
.editProfileLink {
	border-radius: 24px;
	border: 2px solid #000;
	overflow: hidden;
	padding: 20px;
	position: relative;
	cursor: pointer;
}
.editProfileLink img {
	width: 45%;
	border-radius: 50%;
	float: left;
}
.nameAndBio {
	float: right;
	width: calc(55% - 20px);
}
.nameAndBio label {
	color: var(--Eerie-Black, #1C1C1E);
	display: inline-block;
	font-family: var(--bodyl);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.nameAndBio p {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	max-height: 90px;
	overflow: hidden;
	margin: 10px 0 0;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}
.nameAndBio i {
	position: absolute;
	right: 0;
	top: calc(50% - 12px);
}
.bucketListText {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 50px 0 12px;
	display: block;
}
.bucketListInput {
	position: relative;
	margin: 0 20px 50px;
}
.bucketListInput label {
	color: var(--Eerie-Black, #1C1C1E);
	text-align: center;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	display: block;
	margin: 0 44px;
    padding: 8px 0;
    border-bottom: 1px solid #000;
}
.leftNavigation {
	position: absolute;
	left: 0;
	top: calc(50% - 12px);
	cursor: pointer;
}
.rightNavigation {
	position: absolute;
	right: 0;
	top: calc(50% - 12px);
	cursor: pointer;
}
.accountCTA {
	border-radius: 12px;
	border: 1px solid #000;
	padding: 12px;
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	margin-bottom: 20px;
	display: block;
	width: 100%;
	outline: none;
	background: var(--Ghost-White, #F2F2F7);
	text-align: left;
	cursor: pointer;
}
.accountCTA svg {
	float: right;
}
@media screen and (min-width: 913px) {
  	.portfolioSetting,
  	.portfolioSettingContainer,
  	.accountCTA {
    	background: #fff;
  	}
}
