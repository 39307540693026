.faqsContainer {
	padding: 20px 20px 80px;
	background: #fff;
	background-size: 100%;
	background-position: top left -20px, bottom 200px right -150px;
  	background-repeat: no-repeat, no-repeat;
  	min-height: 100%;
}
.heading {
	font-family: var(--font-inter);
	font-size: 24px;
	font-weight: 600;
	margin: 60px 0 30px;
	color: var(--Eerie-Black, #1C1C1E);
}
.faq {
	border-radius: 15px;
	background: var(--Ghost-White, #F2F2F7);
	padding: 20px;
	margin-bottom: 20px;
}
.faq h4 {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0 0 12px;
}
.faq p {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}
.thumbsUp {
	margin-top: 25px;
	display: inline-block;
	margin-left: 40px;
}
.thumbsDown {
	margin-top: 25px;
	display: inline-block;
}
.queryBtn {
	border-radius: 18px;
	background: var(--Eerie-Black, #1C1C1E);
	color: var(--Canary-Yellow, #FFEA00);
	position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
	text-align: center;
	height: 48px;
	border: 2px solid #000;
	padding: 5px 14px;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	cursor: pointer;
	z-index: 10;
}

@media screen and (min-width: 913px) {
    .faqsContainer {
      background-color: #fff;
    }
    .faq {
    	border: 1px solid #F2F2F7;
    }
    .queryBtn {
    	width: 440px;
		left: calc(50% - 220px);
	}
}
