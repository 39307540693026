.accordion {
	border-radius: 15px;
	border: 2px solid var(--Eerie-Black, #1C1C1E);
	padding: 12px 10px;
	position: relative;
}
.accordion h5 {
	color: #1C1C1E;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	margin: 0;
	line-height: normal;
	cursor: pointer;
}
.accordion i {
	display: inline-block;
	float: right;
	height: 18px;
	margin-top: -2px;
	cursor: pointer;
}
.accordion ul {
	width: 100%;
	padding: 0;
	margin: 0;
	overflow: hidden;
}
.accordion li {
	list-style: none;
	color: #1C1C1E;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 4px;
}
.accordion b {
	font-weight: 600;
	font-family: var(--font-inter);
}
.accordion span {
	overflow: hidden;
	display: block;
}
.topContent {
	color: #1C1C1E;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
    padding: 24px 0 10px;
    margin: 0;
    min-height: 95px;
}
.topContent i {
    margin-top: 19px;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: right;
}
.list {
	margin-bottom: 6px;
}
.list label {
	display: block;
}
.ctaBtn {
	display: inline-block;
	border: none;
	outline: none;
	border-radius: 18px;
	background: #FFEA00;
	color: #000;
	text-align: left;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	height: 36px;
	padding: 0 10px;
	padding-left: 15px;
	margin-top: 15px;
	margin-bottom: 10px;
}
.ctaBtn svg {
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  height: 24px;
}



