.searchHere {
  color: #000;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 40px;
  background: #FFF;
  position: absolute;
  width: 210px;
  padding: 11px 0px;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
}
.searchContainer {
  background: #000;
  position: fixed;
  padding: 25px;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  z-index: 12212;
  left: 0;
}
.searchContainer h3 {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 50px 0 5px;
}
.searchContainer p {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 40px;
}
.downArrow {  
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.availableSearch {
  padding: 0;
}
.availableSearch li {
  list-style: none;
  padding: 25px 10px 25px 30px;
  border-radius: 7px;
  background: #F3F3F3;
  margin-bottom: 20px;
  cursor: pointer;
}
.availableSearch h4,
.searchBox h3 {
  color: #000;
  font-family: var(--font-inter);
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.availableSearch label,
.searchBox label {
  color: #000;
  font-family: var(--font-inter);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  width: 80%;
}
.searchBox {
  border-radius: 7px;
  background: #F3F3F3;
  padding: 20px;
}
.inputAndSearch {
  margin-top: 15px;
  overflow: hidden;
}
.inputAndSearch input,
.searchInput {
  border-radius: 21px;
  background: #D9D9D9;
  color: #777;
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
  width: calc(70% - 30px);
  margin-right: 7px;
  padding: 14px 0 14px 23px;
  border: none;
  float: left;
}
.searchInput {
  background: #E1E1E1;
  width: calc(100% - 40px);
  margin: 30px 20px 0 0;
  font-size: 16px;
  color: #A8A8A8;
  border-radius: 24px;
}
.inputAndSearch input:focus,
.searchInput {
  outline: none;
  color: #1C1C1E;
}
.inputAndSearch ::-ms-input-placeholder,
.searchInput ::-ms-input-placeholder { /* Edge 12-18 */
  font-style: italic;
}
.inputAndSearch ::placeholder,
.searchInput ::placeholder {
  font-style: italic;
}
.inputAndSearch button {
  border-radius: 17.5px;
  background: #000;
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 13.804px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  width: 30%;
  padding: 10px 0;
  float: right;
  margin: 3px 0;
}
.suggestionList {
  padding: 0;
  margin: 21px 0 0;
}
.suggestionList li {
  list-style: none;
  border-radius: 24px;
  border: 0.5px solid #000;
  background: #FFF;
  color: #000;
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-right: 8px;
  display: inline-block;
  line-height: 33px;
  padding: 0 20px;
  margin-bottom: 9px;
  cursor: pointer;
}
.selectedTerm {
  background: #FFEA00 !important;
}

@media screen and (min-width: 913px) {
  .searchContainer {
    width: 430px;
    left: calc(50% - 240px);
  }
}








