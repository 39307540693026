.commentsButton {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: var(--Apple-White, #F29E0E);;
  position: absolute;
  right: 30px;
  bottom: 9px;
  text-align: center;
  z-index: 1212;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
}
.commentsButton svg {
  margin-top: 20px;
}
.contactCreatorContainer .contactCreatorModalHeading {
  font-size: 24px;
  color: var(--apple-black, #1C1C1E);
  font-family: var(--bodyl);
  margin-bottom: 24px;
  font-weight: 700;
  text-align: center;
}
.contactCreatorsModalHeadingSuccess {
  color: #000;
  text-align: center;
  font-family: 'Taprom';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contactCreatorContainer input[type=text],
.contactCreatorContainer input[type=number],
.contactCreatorContainer input[type=email],
.contactCreatorContainer textarea {
  width: 100%;
  margin-bottom: 18px;
  padding: 6px 0 6px 10px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border-bottom: 1px solid #000 !important;
  color: rgba(28, 28, 30, 0.50);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.688px;
}
.contactCreatorContainer textarea {
  border: 1px solid #000 !important;
  border-radius: 6px;
}
.contactCreatorContainer input[type=text]:focus,
.contactCreatorContainer input[type=number]:focus,
.contactCreatorContainer input[type=email]:focus,
.contactCreatorContainer textarea:focus {
  outline: none;
}
.contactCreatorContainer input[type=text].invalid,
.contactCreatorContainer input[type=number].invalid,
.contactCreatorContainer input[type=email].invalid {
  border-bottom: 1px solid #e77a7a !important;
}
.contactCreatorContainer textarea.invalid {
  border: 1px solid #e77a7a !important;
}
.contactCreatorContainer input[type=text].valid,
.contactCreatorContainer input[type=number].valid,
.contactCreatorContainer input[type=email].valid,
.contactCreatorContainer textarea.valid  {
  color: var(--apple-black, #1C1C1E) !important;
}
.contactCreatorContainer button[type=submit],
.closeBtn {
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
  background: var(--Apple-Black, #1C1C1E);
  color: #fff;
  text-align: center;
  font-family: var(--bodyl);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 40px;
  margin-top: 22px;
}
.contactCreatorContainer button[type=submit] {
  width: 60%;
}
.closeBtn {
  width: 30%;
  margin-left: 10%;
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.688px
}
.label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--bodyl);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.formSuccessImg {
  max-width: 320px;
  width: 100%;
}
