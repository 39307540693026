.groupDetails {
	background: #fff;
	padding-bottom: 120px;
	min-height: 100%;
}
.backIcon,
.shareIcon {
    border-radius: 25px;
    background: #FFEA00;
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-top: 15px;
    margin-left: 7px;
    position: absolute;
    top: 0;
    z-index: 1;
}
.shareIcon {
    right: 7px;
}
.backIcon {
	left: 0;
}
.groupInfo {
	padding: 10px;
}
.groupTitle {
	margin: 0;
	color: #000;
	text-align: left;
	font-family: var(--font-poppins);
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.duration {
	padding: 4px 8px;
	border-radius: 15px;
	background: #FFEA00;
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	position: absolute;
	right: 10px;
	bottom: 20px;
}
.locationAndSlot {
	margin-top: 10px;
	overflow: hidden;
}
.locationAndSlot p {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 2px 0 0 0;
	width: 50%;
	float: left;
}
.locationAndSlot span {
	margin-left: 6px;
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 38px);
}
.locationAndSlot .city {
	text-decoration-line: underline;
	width: 100%;
}
.groupInfo h2 {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 18px 0 8px;
	text-align: left;
}
.description {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	margin: 0;
}
.videoContainer video {
  height: unset !important;
}
.curators {
	overflow: auto;
	white-space: nowrap;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.curators::-webkit-scrollbar {
  display: none;
}
.curator {
	overflow: hidden;
	white-space: normal;
	display: inline-block;
	vertical-align: top;
}
.curator h2 {
	margin-top: 16px;
}
.curator div {
	width: 50%;
	float: right;
}
.curator img {
	width: calc(50% - 10px);
	float: left;
	border-radius: 10px;
}
.curator label {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.curator p {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	margin: 0;
}
.scrollWrap {
  padding: 10px 0 20px;
  position: relative;
  z-index: 1;
}
.scrollLine {
  height: 1px;
  border-radius: 10px;
  background: #1C1C1E;
  display: block;
}
.scrollBar {
  height: 7px;
  border-radius: 20px;
  background: #FFEA00;
  position: absolute;
  left: 0;
  width: 35px;
  top: 7px;
}
.readMore {
	cursor: pointer;
	font-weight: 600;
}





