.groupInviteContainer .heading {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 24px 0 8px;
}
.info {
  color: #000;
  font-family: var(--font-poppins);
  margin: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 15px;
  text-align: left;
}
.info img {
  vertical-align: top;
  vertical-align: top;
  margin-top: -3px;
  margin-right: 5px;
}
.activitiesDesc {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0 0 10px 0;
}
.success {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-top: 2.5px solid #FFEA00;
  padding-top: 20px;
  padding-bottom: 10px;
}
.groupInviteContainer input[type=text],
.groupInviteContainer input[type=number],
.groupInviteContainer input[type=email],
.groupInviteContainer textarea {
  width: 100%;
  margin-bottom: 18px;
  padding: 6px 0 6px 10px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border: 1px solid #000 !important;
  border-radius: 10px;
  color: rgba(28, 28, 30, 0.50);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.groupInviteContainer input[type=text]:focus,
.groupInviteContainer input[type=number]:focus,
.groupInviteContainer input[type=email]:focus,
.groupInviteContainer textarea:focus {
  outline: none;
}
.groupInviteContainer input[type=text].invalid,
.groupInviteContainer input[type=number].invalid,
.groupInviteContainer input[type=email].invalid {
  border: 1px solid #e77a7a !important;
}
.groupInviteContainer textarea.invalid {
  border: 1px solid #e77a7a !important;
}
.groupInviteContainer input[type=text].valid,
.groupInviteContainer input[type=number].valid,
.groupInviteContainer input[type=email].valid,
.groupInviteContainer textarea.valid  {
  color: var(--apple-black, #1C1C1E) !important;
}
.priceAndJoinBtn {
  padding: 10px;
  background: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: calc(100% - 20px);
}
.priceAndJoinBtn .priceInfo {
  width: 50%;
  float: left;
}
.priceInfo .price {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.price span {
  font-size: 20px;
  font-weight: 600;
  display: block;
}
.token {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.token img {
  width: 20px;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 2px;
}
.priceAndJoinBtn .joinBtn {
  width: 50%;
  float: right;
  border: none;
  outline: none;
  border-radius: 15px;
  background: #FFEA00;
  color: #000;
  font-family: var(--font-inter);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 6px;
  cursor: pointer;
}
.priceAndJoinBtn .info {
  color: #FFF;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  clear: both;
  display: block;
  margin: 0;
}
.joinBtn img {
  width: 30px;
  vertical-align: top;
  display: inline-block;
  margin-top: -2px;
  margin-right: 2px;
}
.label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--bodyl);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.choice,
.choiceSelected {
  padding: 8px;
  display: inline-block;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #000;
  background: #FFF;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.choiceSelected {
  background: #FFEA00;
}
.activityName {
  overflow: hidden;
}
.activityName h4 {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0 0 0 10px;
  display: inline-block;
  width: calc(100% - 180px);
  white-space: normal;
}
.activityName span {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  display: inline-block;
  border-radius: 15px;
  border: 1px solid #000;
  padding: 0 10px;
  float: right;
}
.activityImgWrap {
  position: relative;
}
.activityImg,
.activityImgSelected,
.defaultActivityImgSelected {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}
.activityImgSelected,
.defaultActivityImgSelected {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}
.activityImgSelected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 8px solid #FFEA00;
  box-sizing: border-box;
  pointer-events: none;
}
.creatorTag {
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  border-radius: 15px;
  background: #000;
  padding: 0px 8px;
  position: absolute;
  right: 12px;
  bottom: 12px;
}
.activityTitle {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  display: block;
  white-space: normal;
  margin-top: 5px;
}
.activitySubTitle {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: normal;
}
.activitySubSubTitle {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: normal;
  display: block;
}
.activityDesc {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  white-space: normal;
}
.activitiesContainer {
  width: calc(50% - 20px);
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
}
.activitiesContainer:last-child {
  margin-right: 0;
}
.activityQue {
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
  vertical-align: top;
}
.activityQue:last-child {
  margin-right: 0;
}
.tourWrap {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 50px 0px;
  background: #fff;
  position: absolute;
  border-radius: 8px;
  z-index: 1;
  padding: 5px;
  left: -30px;
  width: 130px;
  top: calc(50% - 20px);
}
.closeIcon {
  float: right;
}
.tourWrap label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
  white-space: normal;
}
.closeIcon svg {
  width: 20px;
  height: 20px;
}
.leftArrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  position: absolute;
  left: -9px;
  top: 12px;
}
.activitiesContainerParent {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
  white-space: nowrap;
  position: relative;
  margin: 10px 0 10px;
}
.activitiesContainerParent::-webkit-scrollbar {
  display: none;
}
.checkIcon {
  width: 40px !important;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.scrollWrap {
  padding: 10px 0 20px;
  position: relative;
  z-index: 1;
}
.scrollLine {
  height: 1px;
  border-radius: 10px;
  background: #1C1C1E;
  display: block;
}
.scrollBar {
  height: 7px;
  border-radius: 20px;
  background: #FFEA00;
  position: absolute;
  left: 0;
  width: 35px;
  top: 7px;
}
.secondStepContainer {
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
}
.innerSecondStep {
  max-height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 10px;
}
.innerSecondStep::-webkit-scrollbar {
  display: none;
}
.backArrow {
  border-radius: 25px;
  background: #FFEA00;
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-top: 15px;
  margin-left: 7px;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  text-align: center;
  cursor: pointer;
}
.backArrow svg {
  margin-top: 10px;
}
.secondStepHeading {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 50px;
}
.groupInfo {
  padding: 20px 20px 30px 20px;
  border-radius: 15px;
  background: #000;
  margin-top: 25px;
  overflow: hidden;
}
.groupInfo h3 {
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 5px 0;
}
.groupInfo .imgWrap {
  display: inline-block;
}
.imgWrap img,
.imgWrap video {
  border-radius: 15px;
}
.groupInfo p {
  width: calc(65% - 10px);
  float: right;
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: top;
  margin: 0;
}
.groupInfo b {
  color: #FFEA00;
  font-weight: 400;
}
.hiwWrap {
  margin-top: 20px;
}
.hiwWrap img {
  width: 100%;
}
.whatsappWrap {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 40px 0;
}
.whatsappWrap a {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid #000;
  background: #FFF;
  display: block;
  width: 185px;
  text-decoration: none;
  line-height: 40px;
  margin: 0 auto;
  margin-top: 5px;
}
.whatsappWrap img {
  width: 30px;
  margin-right: 5px;
  vertical-align: middle;
}
.ourPromise h4,
.team h3 {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0 16px;
}
.ourPromise img {
  width: 100%;
  margin-bottom: 5px; 
}
.teamInner {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
  white-space: nowrap;
}
.teamInner::-webkit-scrollbar {
  display: none;
}
.teamMember {
  width: 175px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: top;
}
.teamMember img {
  width: 175px;
  height: 175px;
  border-radius: 50%;
}
.teamMember label {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin: 4px 0;
}
.teamMember p {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  white-space: normal;
}
.readMore {
  cursor: pointer;
  font-weight: 600;
}

@media screen and (min-width: 913px) {
  html {
    width: 480px;
    margin: 0 auto;
    position: relative;
  }
  .priceAndJoinBtn {
    width: 460px !important;
    left: calc(50% - 240px);
  }
  .secondStepContainer {
    width: 480px;
    left: calc(50% - 240px);
  }
}
