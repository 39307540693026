.summerBreeze {

}
.mainImg,
.footerImg {
	width: 100%;
}
.footerImg {
	margin-top: -5px;
}
.footer {
	background: #0B0B0B;
	padding: 20px 34px 10px;
	margin-top: -10px;
	overflow: hidden;
	position: relative;
}
.footer h3 {
	color: #FFF;
	font-family: "Abril Fatface";
	font-size: 35px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
    width: 60%;
    float: left;
}
.footer .instagram {
    float: right;
    margin-top: 10px;
}
.loadedContainer {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 1;
}
.instagramLinks {
    clear: both;
}
.instagramLinks a {
	color: #FFF;
	text-align: right;
	font-family: var(--font-inter);
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	text-decoration: none;
	display: block;
	margin-top: 5px;
}
