.footerWrap {
  padding: 20px 30px 10px;
  overflow: hidden;
  position: relative;
  border-top: 1px solid #C1C1C1;
  background: #fff;
}
.footerWrap .contactUs {
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.footerWrap label,
.footerWrap p {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin-bottom: 2px;
  cursor: pointer;
}
.contactUs label {
  display: inline-block;
}
.policiesLeft {
  width: 50%;
  float: left;
}
.policiesRight {
  width: 50%;
  float: right;
  text-align: right;
}
.contactUs label {
  margin-bottom: 10px;
}
.insta svg {
  width: 23px;
  margin-left: 8px;
}
.email svg {
  width: 31px;
  margin-left: 8px;
  margin-top: -2px;
}
.contactUs a {
  display: inline-block;
  vertical-align: top;
}
.contactUs .mail {
  margin-top: 0;
}
.footerWrap p {
  text-align: center;
  margin: 0;
  padding-top: 30px;
  clear: both;
}
