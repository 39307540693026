.containers {
  background: #F2F2F7;
  padding: 20px 0 70px 20px;
  overflow: hidden;
}
.tabs {
  overflow: hidden;
  border-radius: 24px;
  background: #FFF;
  padding: 0;
  margin: 50px 20px 0 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.tabs li {
  list-style: none;
  width: 30%;
  float: left;
  text-align: center;
  padding: 12px 0;
  color: #000;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}
li.selectedTab {
  font-size: 18px;
  font-weight: 400;
  border-radius: 22.5px;
  background: #FFEA00;;
}
.filters {
  padding: 0;
  margin: 20px 12px 20px 0;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
}
.filterRemove {
  vertical-align: middle;
  display: inline-block;
  margin-top: 2px;
}
.filters::-webkit-scrollbar {
  display: none;
}
.filters li {
  list-style: none;
  border-radius: 24px;
  background: #FFEA00;
  padding: 7px 11px;
  margin-right: 8px;
  display: inline-block;
  color: #000;
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.results {
  padding: 0 20px 0 0;
  margin: 0;
  overflow: hidden;
  margin-top: 20px;
}
.results li {
  list-style: none;
  position: relative;
  width: calc(50% - 34px);
  float: left;
  border-radius: 3.073px;
  background: #FFF;
  padding: 9px;
  margin-bottom: 20px;
  cursor: pointer;
}
.results li:nth-child(2n) {
  margin-left: 20px;
}
.results img {
  width: 100%;
}
.experiencebytes img {
  width: 100%;
  border-radius: 3px 3px 0 0;
}
.experiencebytes .border {
  width: 100%;
  display: block;
  height: 10px;
  background: #FFEA00;
  border-radius: 0 0 3px 3px;
  margin-top: -10px;
}
.flipCardFront h4 {
  color: #000;
  font-family: var(--font-inter);
  font-size: 10.244px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 6px 0 2px;
}
.flipCardFront h5 {
  color: #000;
  font-family: var(--font-inter);
  font-size: 7.171px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.card {
  height: calc(100% - 18px);
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  width: calc(100% - 18px);
}
.flipCardFront, .flipCardBack {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
}
.flippedByte .card {
  transform: rotateY( 180deg ) ;
  transition: transform 0.5s;
}
.flippedByte {
  background: #FFEA00 !important;
}
.flippedByte i {
  display: none;
}
.flipCardBack {
  transform: rotateY( 180deg );
}
.flipCardBack p {
  margin: 0;
  color: #000;
  font-family: var(--font-inter);
  font-size: 10.244px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.flipCardBack p::-webkit-scrollbar {
  display: none;
}
.shareIcon,
.experienceShareIcon {
  position: absolute;
  top: 8px;
  right: 32px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
}
.experienceShareIcon {
  top: 16px;
  right: 40px;
}
.experiences li {
  padding: 12px;
  list-style: none;
  border-radius: 16.012px;
  background: #1C1C1E;
  cursor: pointer;
  overflow: hidden;
}
.experiences img {
  width: 100%;
  aspect-ratio: 0.7;
  border-radius: 10px;
}
.location,
.recurrence {
  background: #fff;
  border-radius: 15px;
  display: inline-block;
  padding: 2px 6px 0px;
  position: absolute;
  top: 16px;
  left: 17px;
}
.recurrence {
  right: 3px;
  left: unset;
  top: unset;
  bottom: 7px;
}
.location svg {
  width: 8px;
  height: 8px;
  vertical-align: top;
  margin-top: 4px;
}
.location label,
.recurrence label {
  color: var(--apple-black, #1c1c1e);
  font-family: var(--font-inter);
  font-size: 9.683px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 2px;
  vertical-align: top;
  margin-top: 2px;
  display: inline-block;
}
.vibeName {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 12.81px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 11px;
  display: block;
}
.vibeName b {
  font-weight: 500;
  color: var(--Canary, #FFEA00);
}
.experiences h3 {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 8px 0 0;
  min-height: 26px;
}
.experiencePrice span {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 11.619px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.experiencePrice,
.description {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 9.683px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bookExperienceBtn {
  position: absolute;
  width: calc(100% - 24px);
  bottom: 12px;

  color: #FFF;
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-top: 0.5px dashed #fff;
  padding: 17px 0 5px;
  cursor: pointer;
  text-align: center;
}
.bookExperienceBtn svg {
  position: absolute;
  right: 0;
  top: 14px;
}
.experienceDate {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 9px;
}
.experienceDate svg {
  vertical-align: top;
  margin-right: 9px;
}
.creators li {
  width: calc(50% - 28px);
  border-radius: 5.343px;
  overflow: hidden;
}
.creators img {
  border-radius: 3.339px;
  aspect-ratio: 0.7;
}
.creatorInfo .name {
  color: #000;
  font-family: var(--font-inter);
  font-size: 13.358px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 12px 0 4px;
}
.creatorInfo .description {
  color: #000;
  font-family: var(--font-inter);
  font-size: 7.347px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  display: block;
}
li.noResults {
  text-align: center;
  padding: 40px 20px;
  width: calc(100% - 40px);
  border-radius: 20px;
  font-family: var(--font-inter);
  font-size: 16px;
}
.experiences .noResults {
  color: #fff;
}
.exploreMoreLabel {
  border-radius: 7.286px 7.286px 0px 0px;
  background: #000;
  color: #FFEA00;
  font-family: var(--font-inter);
  font-size: 8.015px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  position: absolute;
  bottom: 4px;
  left: calc(50% - 45px);
  width: 90px;
  text-align: center;
  padding: 7px 0;
}




