.customWishList {
	padding: 30px;
	overflow: hidden;
}
.customWishList .heading {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--bodyl);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 50px 0 20px;
}
.para {
	color: #000;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}
.photoSection {
	width: calc(50% - 16px);
	border-radius: 12px;
	background: #D3D3D3;
	position: relative;
	float: left;
}
.photoSection.invalid {
	border: 1px solid #e77a7a;
}
.photoSection .uploadImgPlaceholder {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	bottom: 0;
}
.uploadedImg {
	width: 100%;
	height: 100%;
	border-radius: 12px;
}
.uploadBtnSection {
	width: calc(50% - 16px);
	float: right;
	position: relative;
}
.uploadBtnSection label {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--bodyl);
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 30px;
	display: block;
}
.uploadBtnSection button {
	border-radius: 30px;
	border: 2px solid #000;
	color: var(--Eerie-Black, #1C1C1E);
	text-align: center;
	font-family: var(--bodyl);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 9px 16px;
	cursor: pointer;
}
.uploadBtnSection svg {
	margin-right: 8px;
	vertical-align: text-bottom;
}
.cameraBtn {
	position: absolute;
	bottom: 100px;
	cursor: pointer;
}
.uploadBtn {
	position: absolute;
	bottom: 35px;
}
.cameraScreen {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4444;
    background: #fff;
}
.closeIcon {
	position: absolute;
    background: #000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
	right: 10px;
	top: 10px;
	z-index: 4446;
	cursor: pointer;
}
.closeIcon svg {
	width: 14px;
	height: 11px;
	margin-left: 8px;
	display: inline-block;
	margin-top: 9px;
}
.cameraBtns {
	position: fixed;
    bottom: 10px;
    z-index: 454545332434;
    width: 100%;
    text-align: center;
}
.capturePhotoBtn,
.switchCamera {
    background: transparent;
}
.switchCamera {
	margin-right: 20px;
}
.wishlistForm {
	clear: both;
	padding-top: 40px;
}
.wishlistForm input[type=text],
.wishlistForm textarea {
	width: calc(100% - 20px);
	margin-bottom: 40px;
	padding: 6px 10px;
	box-sizing: border-box;
	background: #fff;
	border: none;
	border-bottom: 1px solid #000 !important;
	font-family: var(--font-montserrat);
	color: var(--Eerie-Black, #1C1C1E);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	background: var(--Ghost-White, #F2F2F7);
	margin-left: 20px;
}
.wishlistForm textarea {
	border: 1px solid #000 !important;
	border-radius: 6px;
}
.wishlistForm input[type=text]:focus,
.wishlistForm textarea:focus {
  	outline: none;
}
.wishlistForm input[type=text].invalid {
  	border-bottom: 1px solid #e77a7a !important;
}
.wishlistForm textarea.invalid {
  	border: 1px solid #e77a7a !important;
}
.wishlistForm input[type=text].valid,
.wishlistForm textarea.valid  {
  	color: var(--apple-black, #1C1C1E) !important;
}
.label {
	text-align: left;
	display: block;
	margin-bottom: 10px;
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.saveWishListBtn,
.discardBtn {
	border: 2px solid #000;
	float: right;
	padding: 5px 14px;
	color: #000;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
    border-radius: 18px;
    background: var(--Eerie-Black, #1C1C1E);
    color: var(--Ghost-White, #F2F2F7);
    margin: 0;
    width: 100%;
    text-align: left;
    padding-left: 30px;
    height: 48px;
    cursor: pointer;
}
.saveWishListBtn svg,
.discardBtn svg {
	display: inline-block;
	margin-right: 5px;
	margin-top: -4px;
	vertical-align: middle;
}
.discardBtn {
	background: transparent;
	margin: 0;
	margin-top: 15px;
	padding-right: 0;
	margin-bottom: 30px;
	padding-left: 30px;
	color: #000;
}
.discardBtn svg {
	margin-left: -7px;
	margin-right: 3px;
}
@media screen and (min-width: 913px) {
  	.customWishList,
  	.wishlistForm input[type=text],
	.wishlistForm textarea,
	.discardBtn,
	.uploadBtnSection button,
	.cameraBtn {
    	background: #fff;
  	}
  	.cameraScreen,
  	.cameraBtns {
  		width: 480px;
		left: calc(50% - 240px);
	}
}

