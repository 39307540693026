.closeIcon {
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
}
.confirmationPopupHeading {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--bodyl);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 40px;
	margin-bottom: 50px;
}
.continueWishListBtn,
.discardBtn {
	border: 2px solid #000;
	float: right;
	padding: 5px 14px;
	color: #000;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
    border-radius: 18px;
    background: var(--Eerie-Black, #1C1C1E);
    color: var(--Ghost-White, #F2F2F7);
    margin: 0;
    width: 100%;
    text-align: left;
    padding-left: 20px;
    height: 48px;
    cursor: pointer;
}
.continueWishListBtn svg,
.discardBtn svg {
	display: inline-block;
	margin-right: 5px;
	margin-top: -4px;
	vertical-align: middle;
}
.discardBtn {
	background: transparent;
	margin: 0;
	margin-top: 15px;
	padding-right: 0;
	margin-bottom: 30px;
	color: #000;
}
.discardBtn svg {
	margin-right: 3px;
}
.confirmationPopup p {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 30px;
}
