.upcomingExperiences {
  background: var(--Apple-Black, #1C1C1E);
  position: relative;
  overflow: hidden;
}
.upcomingExperiencesHeading {
  color: var(--Apple-White, #F2F2F7);
  font-size: 20px;
  margin: 0;
  margin: 0 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: var(--font-inter);
}
.experiencesCarouselParent {
  width: 230px;
  display: inline-block;
  border-radius: 30px;
  background: #FFF;
  margin-right: 30px;
  padding: 20px;
  position: relative;
  margin-top: 20px;
  z-index: 1;
  vertical-align: top;
  cursor: pointer;
}
.experiencesCarouselParent .image {
  aspect-ratio: 0.7;
  border-radius: 12px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 53.89%, rgba(0, 0, 0, 0.52) 68.64%, rgba(0, 0, 0, 0.85) 100%);
}
.experiencesCarousel {
  position: relative;
}
.favouriteIconContainer {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.experienceLocationAndDuration {
  position: absolute;
  border-radius: 6px;
  background: #FFF;
  left: 30px;
  right: 30px;
  top: 30px;
  text-align: left;
  color: var(--apple-black);
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.experienceLocationAndDuration .calendarIcon {
  padding: 9px 8px 8px 5px;
  margin-left: 10px;
  display: inline-block;
}
.experienceLocationAndDuration .experienceDate,
.experienceLocationAndDuration .experienceDuration {
  vertical-align: top;
  line-height: 34px;
}
.experienceLocationAndDuration .timeIcon {
  margin-left: 10px;
  padding: 9px 8px 8px 5px;
  display: inline-block;
}
.experienceInfoAndVibes {
  margin: 15px 10px 0 10px;
  overflow: hidden;
}
.experienceInfo {
  font-family: var(--font-inter);
  text-align: left;
  float: left;
  width: 100%;
}
.experienceInfo .title, .experienceInfo .price {
  display: block;
  text-align: left;
  color: var(--apple-black);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}
.title label {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  font-family: var(--font-inter);
  margin: 5px 0 0;
  line-height: 1.2em;
  display: block;
}
.experienceInfo .price {
  margin-top: 10px;
}
.experienceInfo .unit {
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
}
.experienceInfo .description {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0 5px;
  white-space: normal;
  line-height: 1.2em;
  overflow: hidden;
  min-height: 43px;
}
.groupInfoWrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  right: 30px;
  bottom: 20px;
}
.groupSize {
  color: var(--apple-black);
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 10px;
  left: -2px;
  right: 0;
}
.groupStatus {
  color: var(--apple-black);
  font-family: var(--font-inter);
  font-size: 8px;
  font-weight: 400;
}
.progressIcon {
  width: 40px;
  height: 40px;
}
.scrollWrap {
  margin: 0 30px;
  padding: 40px 0 10px;
  position: relative;
  z-index: 1;
}
.scrollLine {
  height: 1px;
  border-radius: 10px;
  background: #fff;
  display: block;
}
.scrollBar {
  height: 7px;
  border-radius: 20px;
  background: #fff;
  position: absolute;
  left: 0;
  width: 35px;
  top: 37px;
}