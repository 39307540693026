.nomaddetails {
  background: var(--apple-white, #F2F2F7);
  padding-bottom: 40px;
}
.nomaddetailsContainer {
  margin: 85px 30px 30px;
}
.nomadInfoWrap {
  border-radius: 12px;
  background: #FFF;
  padding: 20px;
}
.nameAndImg {
  width: 50%;
  display: inline-block;
  text-align: center;
}
.nameAndImg img {
  width: 102px;
  height: 102px;
  border-radius: 50%;
}
.nameAndImg p {
  color: var(--apple-black, #1C1C1E);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.nameAndImg label {
  color: var(--apple-black, #1C1C1E);
  text-align: center;
  font-family: 'Ubuntu';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.tripAndExp {
  width: 50%;
  display: inline-block;
  color: var(--apple-black, #1C1C1E);
  vertical-align: top;
}
.tripAndExp .trips,
.tripAndExp .exp {
  color: var(--apple-black, #1C1C1E);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.tripAndExp .trips i {
  vertical-align: sub;
}
.tripAndExp .trips label,
.tripAndExp .exp label {
color: var(--apple-black, #1C1C1E);
font-family: 'Ubuntu';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.nomadPlaces {
  padding: 20px 20px 0;
  text-align: center;
}
.nomadPlace {
  display: inline-block;
  margin-bottom: 20px;
  width: 33%;
}
.nomadPlace span {
  border-radius: 6px;
  background: #EE964B;
  padding: 3px 5px;
}
.nomadPlace:nth-child(3n) {
  margin-right: 0;
}
.nomadPlace label {
  color: var(--apple-white, #F2F2F7);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5px;
}
.descriptionContainer p {
  float: right;
  width: calc(100% - 140px);
  vertical-align: top;
  margin: 0;
  color: #000;
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.nomadDetailHeading {
  color: var(--apple-black);
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 140px;
  display: inline-block;
}
.nomadVibesContainer {
  clear: both;
  padding-top: 30px;
  overflow: hidden;
}
.nomadVibes {
  width: calc(100% - 125px);
  float: right;
  margin-top: -15px;
}
.progressIcon {
  width: 40px;
  height: 40px;
}
.vibeWrap {
  width: 40px;
  display: inline-block;
  margin-bottom: 15px;
  text-align: center;
  position: relative;
}
.vibeWrap:nth-child(n) {
  margin-left: 15px;
}
.vibeWrap:nth-child(4) {
  margin-bottom: 0;
}
.vibeName {
  color: var(--apple-black);
  font-family: var(--font-montserrat);
  font-size: 8px;
  font-weight: 400;
}
.vibeIcon {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 8px;
  left: 9px;
  text-align: center;
}
.nomadServicesContainer {
  padding-top: 30px;
  overflow: hidden;
  clear: both;
}
.nomadServices {
  width: calc(100% - 125px);
  float: right;
  margin-top: -30px;
}
.nomadServicesItem {
  width: 50%;
  float: left;
}
.nomadLanguagesContainer {
  padding-top: 30px;
  overflow: hidden;
  clear: both;
}
.nomadLanguage {
  width: 33%;
  float: left;
  margin-bottom: 10px;
}
.upComingTripsContainer {
  margin-top: 40px;
}
.upComingTripsHeading {
  color: #000;
  text-align: center;
  font-family: 'Ubuntu';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin-bottom: 30px;
}
.tripLocationAndDuration {
  position: absolute;
  border-radius: 6px;
  background: #FFF;
  left: 30px;
  right: 30px;
  top: 30px;
  text-align: left;
  color: var(--apple-black);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.tripLocationAndDuration .calendarIcon {
  padding: 9px 12px 8px 5px;
  margin-left: 10px;
  display: inline-block;
}
.tripLocationAndDuration .tripDate,
.tripLocationAndDuration .tripDuration {
  vertical-align: top;
  line-height: 34px;
}
.tripLocationAndDuration .timeIcon {
  margin-left: 10px;
  padding: 9px 12px 8px 5px;
  display: inline-block;
}
.tripInfoAndVibes {
  margin: 15px 10px 0 10px;
  overflow: hidden;
}
.tripInfo {
  font-family: var(--font-montserrat);
  text-align: left;
  float: left;
  width: 100%;
}
.tripInfo .title, .tripInfo .price {
  display: block;
  text-align: left;
  color: var(--apple-black);
  font-family: var(--bodyl);
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tripInfo .price {
  margin-top: 10px;
}
.tripInfo .unit {
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
}
.tripInfo .description {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0 5px;
  width: calc(100% - 50px);
  white-space: normal;
  line-height: 1.2em;
  height: 3.5em;
  overflow: hidden;
}
.groupInfoWrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  right: 30px;
  bottom: 20px;
}
.groupSize {
  color: var(--apple-black);
  font-family: 'Ubuntu';
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}
.groupStatus {
  color: var(--apple-black);
  font-family: var(--font-montserrat);
  font-size: 8px;
  font-weight: 400;
}
.upcomingTripsCarousel {
  position: relative;
}
.upComingTrips {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-left: 30px;
}
