.bookNowButton {
  width: calc(100% - 60px);
  height: 48px;
  border-radius: 25.5px;
  background: #D70A0A;
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 14.305px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.431px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  outline: none;
  border: none;
}
.bookNowButton label {
  display: block;
  font-size: 10px;
  margin-top: 3px;
}
.bookNowContainer .bookNowModalHeading {
  font-size: 24px;
  color: var(--apple-black, #1C1C1E);
  font-family: var(--font-inter);
  margin-bottom: 24px;
  font-weight: 700;
  text-align: center;
}
.bookNowModalHeadingSuccess {
  color: #000;
  text-align: center;
  font-family: 'Taprom';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bookNowContainer input[type=text],
.bookNowContainer input[type=number],
.bookNowContainer input[type=email],
.bookNowContainer textarea {
  width: 100%;
  margin-bottom: 18px;
  padding: 6px 0 6px 10px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border-bottom: 1px solid #000 !important;
  color: rgba(28, 28, 30, 0.50);
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.688px;
}
.bookNowContainer textarea {
  border: 1px solid #000 !important;
  border-radius: 6px;
}
.bookNowContainer input[type=text]:focus,
.bookNowContainer input[type=number]:focus,
.bookNowContainer input[type=email]:focus,
.bookNowContainer textarea:focus {
  outline: none;
}
.bookNowContainer input[type=text].invalid,
.bookNowContainer input[type=number].invalid,
.bookNowContainer input[type=email].invalid {
  border-bottom: 1px solid #e77a7a !important;
}
.bookNowContainer textarea.invalid {
  border: 1px solid #e77a7a !important;
}
.bookNowContainer input[type=text].valid,
.bookNowContainer input[type=number].valid,
.bookNowContainer input[type=email].valid,
.bookNowContainer textarea.valid  {
  color: var(--apple-black, #1C1C1E) !important;
}
.bookNowContainer button[type=submit],
.closeBtn {
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
  background: var(--Apple-Black, #1C1C1E);
  color: #fff;
  text-align: center;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 40px;
  margin-top: 22px;
}
.bookNowContainer button[type=submit] {
  width: 60%;
}
.closeBtn {
  width: 30%;
  margin-left: 10%;
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.688px
}
.label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.formSuccessImg {
  max-width: 320px;
  width: 100%;
}
.message {
  font-family: var(--font-inter);
  font-size: 12px;
}
