.bookingSuccess {
	padding: 0 10px 20px;
	background: #84c284;
	position: relative;
	min-height: 100%;
}
.pageHeading {
  	color: #000;
  	text-align: center;
  	font-family: var(--font-chewy);
  	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 1px;
	margin: 0;
	padding: 22px;
}
.backIcon {
	border-radius: 25px;
	background: #FFEA00;
	width: 50px;
	height: 50px;
	display: inline-block;
	margin-top: 10px;
	margin-left: 10px;
	position: fixed;
	top: 0;
	z-index: 3;
	left: 0;
}
.topSection h2 {
	color: #1C1C1E;
	text-align: center;
	font-family: var(--font-inter);
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	margin: 0 10px;
}
.topSection p {
	color: #1C1C1E;
	text-align: center;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 24px;
	margin: 5px 0 20px;
}
.packageWrap {
	margin: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
   -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}
.packageInfo {
    border-radius: 10px 0 0 10px;
    width: calc(60% - 22px);
    float: left;
    padding: 10px;
    text-align: left;
    border-left: 1px solid #362C23;
    border-top: 1px solid #362C23;
    border-bottom: 1px solid #362C23;
    border-right: 1px solid transparent;
    position: relative;
}
.packageInfo h5 {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin: 0;
}
.packageInfo label {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}
.packageInfo p {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 12px;
	margin: 0;
}
.selectedpackageWrap {
    background: rgba(255, 234, 0, 0.80);
}
.selectedpackageWrap .packageInfo {
	border: none;
	padding: 11px;
}
.packageImgWrap {
	width: 40%;
	float: right;
	background-size: contain;
}
.counts {
	margin-top: 10px;
	text-align: center;
	position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
}
.counts span {
	font-weight: 600;
	margin: 3px 12px 0;
	min-width: 24px;
	text-align: center;
	vertical-align: top;
	color: var(--Eerie-Black, #1c1c1e);
	display: inline-block;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	line-height: 24px;
}
.recurrencesList {
	margin-top: 10px;
	padding: 0 10px;
}
.recurrencesList li {
	list-style: none;
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	margin-bottom: 10px;
	text-align: center;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
   -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.recurrencesList span {
	width: calc(67% - 1px);
	display: inline-block;
	border-left: 1px solid #362C23;
	border-top: 1px solid #362C23;
	border-bottom: 1px solid #362C23;
	border-radius: 10px 0 0 10px;
	line-height: 38px;
}
.recurrencesList label {
	width: 33%;
	display: inline-block;
	background: #362C23;
	color: #fff;
	border-radius: 0 10px 10px 0;
	line-height: 40px;
}
.selectedRecurrence span {
	background: rgba(255, 234, 0, 0.80);
	border: none;
	padding: 1px 1px 1px 0;
}
.priceDetails {
	border-radius: 15px;
	background: var(--Ghost-White, #F2F2F7);
	padding: 20px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	margin: 10px;
}
.priceDetails ul {
	padding: 0;
	margin: 0;
}
.priceDetails li {
	list-style: none;
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding-bottom: 12px;
	border-bottom: 1px solid #CACACE;
	margin-bottom: 12px;
	overflow: hidden;
}
.priceDetails p {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 7px 0 0;
}
.priceDetails span {
	float: right;
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.finalAmount {
    margin-top: 12px;
    font-weight: 600;
}
.priceNote {
	border: none !important;
	padding: 0 !important;
	margin: 0 !important;
	font-size: 12px !important;
	font-weight: 300 !important;
}

@media screen and (min-width: 913px) {
  .backIcon {
    left: calc(100% / 2 - 230px);
    cursor: pointer;
  }
}
