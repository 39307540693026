.backIconContainer {
	position: fixed;
	top: 40px;
	left: 30px;
	z-index: 1212;
}
.backIconContainer .backIcon {
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 14px);
    cursor: pointer;
}
@media screen and (min-width: 913px) {
  	.backIconContainer {
    	left: calc(100% / 2 - 210px);
  	}
}