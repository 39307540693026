.favouriteIconContainer {
	position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1212;
    cursor: pointer;
}
.favouriteIconContainer .favouriteIcon {
    position: absolute;
	left: calc(50% - 12px);
	top: calc(50% - 14px);
}
