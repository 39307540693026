@import url("https://fonts.googleapis.com/css?family=Ubuntu:wght@100,200,300,400;500;800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:wght@100,200,300,400;500;800&display=swap");
@import url('https://fonts.googleapis.com/css?family=Inter:wght@100,200,300,400;500;600');
@import url("https://fonts.googleapis.com/css?family=Taprom:wght@100,200,300,400;500;800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open Sans:wght@100,200,300,400;500;800&display=swap");
@import url('https://fonts.googleapis.com/css?family=Source Serif Pro');
@import url('https://fonts.googleapis.com/css?family=Chewy');
@import url('https://fonts.googleapis.com/css?family=Poppins:wght@100,200,300,400;500;800&display=swap');


html {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  line-height: normal;
  height: 100%;
  overflow-y: scroll;
  background-color: #F2F2F7;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}
#root {
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#root::-webkit-scrollbar {
  display: none;
}
:root {
  /* fonts */
  --bodyl: 'Ubuntu';
  --font-montserrat: 'Montserrat';
  --font-inter: 'Inter';
  --font-chewy: 'Chewy';
  --font-poppins: 'Poppins';

  /* font sizes */
  --bodyl-size: 16px;
  --bodys-size: 12px;
  --bodym-size: 14px;
  --font-size-lg: 18px;
  --font-size-5xs: 8px;
  --font-size-3xs: 10px;
  --font-size-5xl: 24px;
  --headm-size: 28px;

  /* Colors */
  --wire-1: #eff2f4;
  --wire-2: #F2F2F7;
  --wire-4: #456386;
  --wire-5: #1C1C1E;
  --white: #FFF;
  --apple-black: #1C1C1E;
  --color-steelblue-100: rgba(69, 99, 134, 0.2);
  --wire-w: #fff;
  --m3-sys-light-on-surface-variant: #49454f;
  --wire-black: #404851;
  --m3-sys-light-outline: #79747e;
  --m3-ref-neutral-neutral10: #1c1b1f;
  --color-lavender: #dbe8f6;
  --color-black: #000;
  --m3-sys-light-error: #b3261e;
  --wire-3: #9fb7d3;
  --m3-sys-light-surface: #fffbfe;
  --color-limegreen: #6bf160;

  /* Gaps */
  --gap-9xs: 4px;
  --gap-8xs: 5px;
  --gap-5xs: 8px;
  --gap-11xs: 2px;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-5xl: 24px;
  --padding-9xs: 4px;
  --padding-5xs: 8px;
  --padding-mini: 15px;
  --padding-base: 16px;
  --padding-xs: 12px;
  --padding-xl: 20px;
  --padding-8xs: 5px;
  --padding-11xs: 2px;

  /* border radiuses */
  --br-lg: 18px;
  --br-81xl: 100px;
  --br-65xl: 84px;
  --br-3xs: 10px;
  --br-8xs: 5px;
  --br-mini: 15px;
  --br-base: 16px;
  --br-xl: 20px;
  --br-55xl: 74px;
}

input:focus {
  outline: none;
}
.nomadTripsCarouselParent {
  margin: 20px 20px 15px;
  position: relative;
}
.nomadTripsCarouselParent {
  margin: 0;
  padding: 20px;
  border-radius: 30px;
  background: #fff;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  width: 250px;
}
.nomadTripsCarouselParent .carousel-slider,
.creatorsContainer .carousel-slider,
.upcomingExperienceContainer .carousel-slider,
.itineraryCarouselParent .carousel-slider  {
  border-radius: 12px;
}
.nomadTripsCarouselParent .slide img {
  aspect-ratio: 1 / 0.9;
}
.creatorsContainer .control-dots,
.upcomingExperienceContainer .control-dots,
.vibesCarouselParent .control-dots {
  padding-right: 25px;
  text-align: right;
}
.destinationsCarousels .control-dots {
  text-align: left;
  bottom: 15px;
  left: 30px;
}
.nomadTripsCarouselParent .control-dots,
.itineraryCarouselParent .control-dots {
  text-align: center;
}
.featuredCarousel {
  padding-bottom: 25px;
}
.featuredCarousel .control-dots {
  bottom: -30px;
}
.featuredCarousel .control-dots .dot {
  margin: 0 8px;
  width: 8px;
  height: 8px;
  background: #D9D9D9;
  opacity: 0.5;
  box-shadow: none;
}
.featuredCarousel img {
  border-radius: 10px;
}
.featuredCarousel .carousel.carousel-slider {
  overflow: initial;
}
.featuredCarousel .carousel .control-dots .dot.selected,
.featuredCarousel .carousel .control-dots .dot:hover {
  background: #FFEA00;
}
.nomadTripsCarouselParent .control-dots .dot,
.creatorsContainer .control-dots .dot, 
.upcomingExperienceContainer .control-dots .dot,
.vibesCarouselParent .control-dots .dot,
.experienceImagesCarouselParent .control-dots .dot,
.groupImagesCarouselParent .control-dots .dot,
.itineraryCarouselParent .control-dots .dot,
.destinationsCarousels .control-dots .dot {
  margin: 0 5px;
  width: 7px;
  height: 7px;
  background: var(--wire-2);
  opacity: 0.5;
  box-shadow: none;
}
.experienceImagesCarouselParent .control-dots {
  text-align: right;
  padding-right: 10px;
  margin: 5px 0;
  z-index: 1;
}
.groupImagesCarouselParent .control-dots {
  margin: 5px 0;
}
.experienceImagesCarouselParent,
.groupImagesCarouselParent {
  position: relative;
}
.destinationsCarousels .control-dots .dot {
  width: 11px;
  height: 11px;
  background: rgba(217, 217, 217, 1);
}
.destinationsCarousels .carousel .control-dots .dot.selected,
.destinationsCarousels .carousel .control-dots .dot:hover {
  background: rgba(0, 0, 0, 1);
}
.tripFaqContainer .faq-row-wrapper {
  border-radius: 12px;
  background: #FFF;
  padding: 20px;
  font-family: var(--font-montserrat);
}
.groupFaqContainer .faq-row-wrapper,
.genericFaqContainer .faq-row-wrapper {
  background: #FFF;
  font-family: var(--font-poppins);
}
.tripFaqContainer .faq-row-wrapper .faq-title {
  border: none;
}
.groupFaqContainer .faq-row-wrapper .faq-title,
.genericFaqContainer .faq-row-wrapper .faq-title {
  color: #000;
  border: none;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.tripFaqContainer .faq-row-wrapper .faq-title h2 {
  color: var(--apple-black);
  font-family: 'Ubuntu';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
}
.genericFaqContainer .faq-row-wrapper .faq-title h2 {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.groupFaqContainer .faq-row-wrapper .faq-title h2 {
  font-size: 24px;
  font-family: var(--font-poppins);
  font-style: normal;
  font-weight: 400;
  margin: 0;
}
.tripFaqContainer .faq-row-wrapper .faq-body .faq-row,
.groupFaqContainer .faq-row-wrapper .faq-body .faq-row,
.genericFaqContainer .faq-row-wrapper .faq-body .faq-row {
  border: none;
}
.tripFaqContainer .faq-row-wrapper .faq-body .faq-row .arrow-image {
  width: 16px;
}
.groupFaqContainer .faq-row-wrapper .faq-body .faq-row .arrow-image,
.genericFaqContainer .faq-row-wrapper .faq-body .faq-row .arrow-image {
  width: 24px;

}
.groupFaqContainer .faq-row-wrapper .faq-body .faq-row .arrow-image svg,
.genericFaqContainer .faq-row-wrapper .faq-body .faq-row .arrow-image svg {
  fill: #000;
}
.groupFaqContainer .faq-row-wrapper .faq-body .faq-row .row-title,
.genericFaqContainer .faq-row-wrapper .faq-body .faq-row .row-title {
  padding: 5px 0;
}
.tripFaqContainer .faq-row-wrapper .faq-body .faq-row .row-title .row-title-text {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.groupFaqContainer .faq-row-wrapper .faq-body .faq-row .row-title .row-title-text,
.genericFaqContainer .faq-row-wrapper .faq-body .faq-row .row-title .row-title-text {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  display: block;
  white-space: normal;
  margin-top: 5px;
}
.tripFaqContainer .faq-row-wrapper .faq-body .faq-row .row-content .row-content-text {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
.groupFaqContainer .faq-row-wrapper .faq-body .faq-row .row-content .row-content-text,
.genericFaqContainer .faq-row-wrapper .faq-body .faq-row .row-content .row-content-text {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  white-space: normal;
}
.creatorsContainer,
.upcomingExperienceContainer,
.bytesContainer,
.immersionsWrap {
  overflow: auto;
  white-space: nowrap;
  padding-left: 30px;
  position: relative;
  z-index: 2;
}
.bytesContainer {
  padding: 0;
}
.immersionsWrap {
  padding-left: 20px;
}
.upcomingExperienceContainer::-webkit-scrollbar,
.creatorsContainer::-webkit-scrollbar,
.bytesContainer::-webkit-scrollbar,
.immersionsWrap::-webkit-scrollbar {
  display: none;
}
.upcomingExperienceContainer,
.creatorsContainer,
.bytesContainer,
.immersionsWrap {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.immersionsWrap {
  margin-top: 10px;
}
.creatorsContainer {
  margin-top: 20px;
}


/* Blog css starts */
.article {
  margin: 0 22px;
  padding-bottom: 30px;
}
.article .blogHeading {
  letter-spacing: -0.014em;
  line-height: 38px;
  margin-bottom: 24px;
  margin-top: 1.01em;
  font-size: 31px;
  font-weight: 700;
  font-style: normal;
  color: #242424;
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-wrap: break-word;
  font-weight: bold;
}
.article img {
  width: 100%;
  margin-top: 40px;
}
.article p {
  letter-spacing: -0.003em;
  line-height: 28px;
  margin-top: 1.56em;
  font-size: 18px;
  margin-bottom: -0.46em;
  font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  word-break: break-word;
  font-style: normal;
  color: #242424;
  font-weight: 400;
}
.article h2 {
  margin-top: 1.23em;
  line-height: 20px;
  font-size: 16px;
  margin-bottom: -0.31em;
  font-weight: 600;
  font-style: normal;
  color: #242424;
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.article h3 {
  margin-top: 1.23em;
  line-height: 20px;
  font-size: 20px;
  margin-bottom: -0.31em;
  font-weight: 600;
  font-style: normal;
  color: #242424;
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.article blockquote {
  margin-left: -20px;
  padding-left: 23px;
}
.article ul {
  padding: 0;
    list-style: none;
    list-style-image: none;
}
.article li {
  letter-spacing: -0.003em;
  line-height: 28px;
  margin-top: 1.56em;
  font-size: 18px;
  padding-left: 0px;
  margin-left: 30px;
  list-style-type: disc;
  margin-bottom: -0.46em;
  font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  font-style: normal;
  color: #242424;
  font-weight: 400;
}
.article a {
  color: #242424;
}
/* Blog css ends */

.hide-carousel-childs .css-1fzpoyk:first-child,
.hide-carousel-childs .css-1fzpoyk:last-child {
  opacity: 0 !important;
}
.user-vibes-carousel .css-1fzpoyk:first-child {
  opacity: 0 !important; 
}
.user-vibes-carousel .css-1fzpoyk {
  margin-left: -25px;
}
.destinationsCarousels {
  margin-top: -95px;
}

@media screen and (min-width: 913px) {
  html {
    width: 480px;
    margin: 0 auto;
    position: relative;
    border: 1px solid #F2F2F7;
  }
}