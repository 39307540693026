.contactUs {
  padding: 40px 30px;
}
.contactUs h3 {
  color: #000;
  font-family: var(--font-inter);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.contactUs h4 {
  color: #000;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.successMsg {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  line-height: normal;
}
.contactUs input[type=number],
.contactUs textarea {
  width: 100%;
  margin-bottom: 18px;
  padding: 6px 0 6px 10px;
  box-sizing: border-box;
  background: #F2F2F7;
  border: none;
  border-bottom: 1px solid #C1C1C1 !important;
  font-family: var(--font-inter);
  color: #949494;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.contactUs ::-ms-input-placeholder { /* Edge 12-18 */
  font-style: italic;
}
.contactUs ::placeholder {
  font-style: italic;
}
.contactUs textarea {
  border: 1px solid #C1C1C1 !important;
  border-radius: 6px;
}
.contactUs input[type=number]:focus,
.contactUs textarea:focus {
  outline: none;
}
.contactUs input[type=number].invalid {
  border-bottom: 1px solid #e77a7a !important;
}
.contactUs textarea.invalid {
  border: 1px solid #e77a7a !important;
}
.contactUs input[type=number].valid,
.contactUs textarea.valid  {
  color: var(--apple-black, #1C1C1E) !important;
}
.contactUs button[type=submit] {
  border: none;
  cursor: pointer;
  width: 116px;
  border-radius: 20px;
  background: rgba(255, 234, 0, 1);
  color: #000;
  text-align: center;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 40px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}