.groupsHeading {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  margin: 20px 20px 10px 20px;
  display: inline-block;
  padding-right: 20px;
  border-bottom: 2.5px solid #FFEA00;
}
.groupsSubHeading {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0 10px 20px 20px;
  display: block;
}
.groupsCarousel {
  padding-left: 20px;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0;
}
.groupsCarousel::-webkit-scrollbar {
  display: none;
}
.groups {
  background: #fff;
}
.group {
  list-style: none;
  cursor: pointer;
  width: 200px;
  position: relative;
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
}
.group img {
  border-radius: 15px;
  width: 100%;
}
.group h3 {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-wrap: wrap;
}
.group p {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  margin: 2px 0;
  white-space: normal;
}
.videoContainer video {
  border-radius: 15px;
}
