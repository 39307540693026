.shareContainer {
	position: fixed;
    top: 20px;
    right: 80px;
    z-index: 1212;
}
.shareContainer .shareIcon {
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 14px);
    cursor: pointer;
}
.shareBtn {
	position: relative;
	border-radius: 18px;
	background: var(--Eerie-Black, #1C1C1E);
	width: 140px;
	cursor: pointer;
}
.shareBtn .shareIcon {
	left: 27px;
	top: 12px;
	cursor: pointer;
}
.shareBtn span {
	color: var(--Ghost-White, #F2F2F7);
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	margin-left: 60px;
	line-height: 48px;
	cursor: pointer;
}
