.resetPassword {
	background: #060B0E;
	min-height: 100%;
}
.resetPasswordImg {
  position: absolute;
  right: 0;
  top: 0;
}
.resetPasswordContainer {
  padding-top: 170px;
  position: relative;
}
.resetPasswordInnerContainer,
.resetPasswordInnerContainer2 {
	clear: both;
	padding: 90px 30px 50px 30px;
	background-repeat: no-repeat;
  background-size: 100% 100%;
}
.resetPasswordInnerContainer2 {
  padding-bottom: 70px;
}
.resetPassword input[type=password] {
	width: 100%;
	margin-bottom: 25px;
	box-sizing: border-box;
	background: #fff;
	color: rgba(28, 28, 30, 0.50);
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.688px;
	border-radius: 12px;
	border: 2px solid #000;
	padding: 16px;
}
.resetPassword input[type=password]:focus {
  outline: none;
}
.resetPassword input[type=password].invalid {
  border: 2px solid #e77a7a !important;
}
.resetPassword input[type=password].valid {
  color: var(--apple-black, #1C1C1E) !important;
}
.resetPassword button[type=submit] {
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 18px;
  background: var(--Apple-Black, #1C1C1E);
  color: var(--Ghost-White, #F2F2F7);
  text-align: center;
  font-family: var(--bodyl);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 50px;
}
.headingTxt {
  color: var(--Eerie-Black, #1C1C1E);
  font-family: var(--bodyl);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 15px 0 0 0;
  position: relative;
  margin-bottom: 50px;
}
.goBackToLoginLabel {
  color: var(--Eerie-Black, #1C1C1E);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 30px;
  display: inline-block;
  margin-bottom: 60px;
}
.goBackToLoginLabel svg {
  vertical-align: middle;
  margin-top: -3px;
}
.errorMessage {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.errorMessage label {
  display: block;
}
