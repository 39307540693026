.list {
  margin: 0;
  padding: 10px 20px 0 18px;
}
.list .listItem {
  color: #1C1C1E;
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  list-style: none;
  margin-bottom: 5px;
}
.list .listItem::before {
  content: "\2022";
  color: #1C1C1E;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}
