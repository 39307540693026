.brand {
	background: #fff;
	height: 100%;
}
.topSection {
  position: sticky;
  top: 0;
  padding: 30px 30px 80px 30px;
  height: 250px;
}
.shareIcon {
    border-radius: 25px;
    background: #FFEA00;
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-top: 10px;
    position: absolute;
    top: 0;
    z-index: 1;
    right: 10px;
}
.brandDetails {
  position: relative;
  background: #fff;
  padding: 0 12px 40px 12px;
}
.bordersTop {
  border-radius: 30px 30px 0 0;
  background: #fff;
  position: absolute;
  top: -20px;
  height: 21px;
  width: 100%;
  display: block;
  left: 0;
}
.logoAndName {

}
.logoAndName img {
	border-radius: 50%;
	width: 100px;
	height: 100px;
	margin-top: 2px;
}
.brandName {
	width: calc(100% - 120px);
	float: right;
	margin-top: 10px;
}
.brandName h3 {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
}
.brandName h4,
.brandName span {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}
.brandDetails h2 {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	margin: 24px 0 8px;
}
.aboutUs p {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}
.story img {
	width: 100%;
	border-radius: 10px;
	margin-top: 8px;
}
.story p {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	margin: 0;
}
.curators {
	overflow: auto;
	white-space: nowrap;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.curators::-webkit-scrollbar {
  display: none;
}
.curator {
	overflow: hidden;
	white-space: normal;
	display: inline-block;
	vertical-align: top;
}
.curator h2 {
	margin-top: 16px;
}
.curator div {
	width: 50%;
	float: right;
}
.curator img {
	width: calc(50% - 10px);
	float: left;
	border-radius: 10px;
}
.curator label {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.curator p {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	margin: 0;
}
.scrollWrap {
  padding: 10px 0 10px;
  position: relative;
  z-index: 1;
}
.scrollLine {
  height: 1px;
  border-radius: 10px;
  background: #1C1C1E;
  display: block;
}
.scrollBar {
  height: 7px;
  border-radius: 20px;
  background: #FFEA00;
  position: absolute;
  left: 0;
  width: 35px;
  top: 7px;
}
.groupsData {
	padding: 0;
	margin-top: 8px;
	list-style: none;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: auto;
	white-space: nowrap;
}
.group {
	overflow: hidden;
	white-space: normal;
	display: inline-block;
	vertical-align: top;
	width: 80%;
	margin-right: 8px;
	cursor: pointer;
}
.group:last-child {
	margin-right: 0;
}
.group img {
  border-radius: 15px;
  width: 100%;
  aspect-ratio: 3/4;
}
.group h3 {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-wrap: wrap;
}
.group .description {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  margin: 2px 0;
  white-space: normal;
}
.idealFor {
  border-radius: 5px;
  background: rgba(255, 234, 0, 0.90);
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 2px 5px;
  position: absolute;
  right: 10px;
  bottom: 13px;
  cursor: pointer;
  left: 10px;
  right: auto;
}
.gpCurator,
.gpPrice {
  text-wrap: wrap;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
}
.location {
  text-wrap: wrap;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0;
}
.location svg {
  margin-right: 4px;
}
.location span {
  vertical-align: super;
}
.videoContainer2 video {
  border-radius: 15px;
  width: unset !important;
}
.videoContainer3 video {
  border-radius: 10px;
  width: unset !important;
}
.images {
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: auto;
	white-space: nowrap;
}
.imagesContainer {
	width: 80%;
	display: inline-block;
	margin-right: 4px;
	vertical-align: top;
}
.imagesContainer:last-child {
	margin: 0;
}
.imagesContainer img {
	width: 100%;
	border-radius: 10px;
}
.reviewWrap {
	white-space: nowrap;
    overflow: auto;
}
.reviewsContainers .review {
	width: 185px;
	border-radius: 12px;
	background: #FFF;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 8px;
}
.reviewsContainers .review img {
	width: 100%;
	height: 100%;
	border-radius: 15px;
	margin-right: 15px;
}
.reviewsContainers .traveller {
	display: block;
}
.reviewsContainers .travellerName {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	display: block;
}
.reviewText {
	color: #000;
	white-space: initial;
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 0;
}
.reviewWrap::-webkit-scrollbar {
  display: none;
}
.reviewWrap {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.contactBrand {
	overflow: hidden;
}
.whatsappWrap,
.instaWrap {
	width: calc(50% - 28px);
	margin-right: 4px;
	border-radius: 10px;
	border: 1px solid #000;
	padding: 5px 10px;
	float: left;
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 25px;
	text-decoration: none;
	text-align: center;
}
.instaWrap {
	float: right;
	margin: 0;
}
.contactBrand img {
	width: 30px;
	margin-right: 5px;
	vertical-align: middle;
	margin-top: -2px;
}
.readMore {
	cursor: pointer;
	font-weight: 600;
}












