.instructionContainer {
	padding: 20px;
	background: #fff;
	min-height: 100%;
}
.heading {
	font-family: var(--font-inter);
	font-size: 24px;
	font-weight: 600;
	margin: 60px 0 30px;
	color: #1C1C1E;
}
.queryBtn {
	border-radius: 18px;
	background: var(--Eerie-Black, #1C1C1E);
	color: var(--Canary-Yellow, #FFEA00);
	position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
	text-align: center;
	height: 48px;
	border: 2px solid #000;
	padding: 5px 14px;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	cursor: pointer;
	z-index: 10;
}
.note,
.title {
	font-family: var(--font-inter);
	font-size: 16px;
	color: #1C1C1E;
	list-style: none;
	margin-bottom: 80px;
	font-weight: 600;
	margin-top: 30px;
}
.title {
	margin-bottom: 10px;
}
.instructionList {
	padding: 0;
}
.instructionList li {
	list-style: none;
	margin-bottom: 15px;
	position: relative;
}
.instructionList {
	font-family: var(--font-inter);
	font-size: 16px;
	color: #1C1C1E;
	list-style: none;
	margin-bottom: 30px;
	font-weight: 600;
	margin-top: 30px;
}
.instructionList i {
	position: absolute;
	left: 0;
	top: 0;
	font-style: normal;
}
.instructionList span {
    padding-left: 20px;
    display: block;
    font-weight: 400;
}

@media screen and (min-width: 913px) {
    .instructionContainer {
      background-color: #fff;
    }
    .queryBtn {
    	width: 440px;
		left: calc(50% - 220px);
	}
}
