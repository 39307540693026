.vibeCheck {
  position: relative;
  background: #fff;
  height: 100%;
}
.logo {
  display: inline-block;
  margin-left: 12px;
  margin-top: 6px;
}
.loadingScreen {
  background: #fff;
  padding: 30px;
}
.loadingScreen h2 {
  padding-top: 60px;
  color: var(--Eerie-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.loadingScreen h2 {
  padding-top: 30px;
}
.loadingScreen label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 10px 0 30px;
  display: block;
}
.loadingScreen {
  overflow: hidden;
}
.loadingScreen .uploading {
  display: block;
  text-align: center;
  position: absolute;
  left: 0;
  overflow: hidden;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.userVibes {
  background: #fff;
}
.userVibesContainer {
  position: relative;
  margin-top: 20px;
  overflow: hidden;
  padding: 20px;
}
.vibeDesc {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}
.userVibesHeading {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  margin: 0 0 25px;
}
.userVibesHeading b {
  font-family: var(--font-chewy);
  font-size: 32px;
  font-weight: 400;
}
.dominant,
.subtle {
  border-radius: 15px;
  background: linear-gradient(270deg, #FAFAFA 0%, #ECECEC 100%);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}
.vibeElement {
  width: 33%;
  float: left;
  position: relative;
  margin-top: 20px;
  padding-bottom: 16px;
}
.vibeElement img {
  width: 80%;
  z-index: 1;
  position: relative;
  margin-left: -15px;
  margin-top: 15px;
}
.vibeHeading {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 10px;
}
.vibePolygon {
  width: 70px;
  position: absolute;
  top: 30px;
  right: 5px;
  height: 70px;
  background-size: cover;
  text-align: center;
}
.vibePolygon label {
  color: #000;
  text-align: center;
  font-family: var(--font-chewy);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  display: inline-block;
  margin-left: 6px;
  position: relative;
  z-index: 1;
}
.brands {
  background: #fff;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.brandsContainer {
  padding-left: 20px;
  overflow: auto;
  position: relative;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.brandsContainer::-webkit-scrollbar,
.experiencesCarousel::-webkit-scrollbar {
  display: none;
}
.creatorsWrap {
  width: 230px;
  display: inline-block;
  border-radius: 8px;
  background: #F2F2F7;
  margin-right: 20px;
  padding: 12px;
  position: relative;
  z-index: 1;
  vertical-align: top;
  cursor: pointer;
}
.creatorsWrap .image {
  width: 100%;
  border-radius: 5px;
  height: 230px;
}
.creatorInfo {
  margin-top: 19px;
}
.creatorInfo .name {
  color: #000;
  font-family: var(--font-inter);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
  cursor: pointer;
}
.creatorInfo .description {
  color: #000;
  font-family: var(--font-inter);
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 6px;
  white-space: normal;
  display: block;
  cursor: pointer;
}
.exploreMore {
  background: transparent !important;
}
.exploreMore h3 {
  color: #FFEA00 !important;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: calc(50% - 25px);
}
.experiences {
  background: #fff;
}
.exHeading,
.brandsHeading,
.blogs h2 {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  margin: 20px 20px 10px 20px;
  display: inline-block;
  padding-right: 20px;
  border-bottom: 2.5px solid #FFEA00;
}
.brandsHeading {
  margin-bottom: 20px;
}
.exSubHeading {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0 10px 20px 20px;
  display: block;
}
.experience {
  padding: 12px;
  list-style: none;
  border-radius: 15px;
  background: #1C1C1E;
  cursor: pointer;
  width: 230px;
  position: relative;
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
}
.moreExperiences {
  width: 230px;
  display: inline-block;
  margin-right: 20px;
  text-align: center;
  position: relative;
}
.moreExperiencesWrap {
  position: absolute;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 20px);
}
.moreExperiences label {
  color: #000;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
}
.experience img {
  width: 100%;
  border-radius: 12px;
  aspect-ratio: 1;
}
.price,
.slots {
  color: #FFF;
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
  display: block;
}
.duration {
  color: #FFF;
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  position: absolute;
  right: 12px;
  bottom: 12px;
}
.duration b {
  font-size: 20px;
  font-weight: 600;
}
.vibeName {
  color: var(--Canary, #FFEA00);
  font-family: var(--font-inter);
  font-size: 12.81px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 11px;
  display: block;
}
.vibeName b {
  color: #fff;
  font-weight: 300;
}
.experience h3 {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 8px 0 0;
  white-space: normal;
}
.experiencesCarousel {
  padding-left: 20px;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0;
}
.userVibes .experiences {
  border-radius: 15px 15px 0 0;
}
.userVibes .brands {
  border-radius: 0 0 15px 15px;
  padding-bottom: 30px;
}
.loginPopup {
  padding: 35px 20px;
  border-radius: 15px;
  background: var(--White, #FFF);
  margin: 30px;
  position: fixed;
  z-index: 4;
  width: calc(100% - 100px);
  bottom: 100px;
  border: 1px solid #ECECEC;
}
.loginPopup h3 {
  color: var(--Black, #000);
  font-family: var(--font-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 0 30px 20px 30px;
}
.loginPopup span {
  color: var(--Black, #000);
  text-align: center;
  font-family: var(--font-inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-decoration-line: underline;
  margin-top: 20px;
  display: block;
  cursor: pointer;
}
.loginSignupBtn {
  border: none;
  cursor: pointer;
  border-radius: 15px;
  background: var(--Black, #000);
  color: var(--Canary-Yellow, #FFEA00);
  text-align: center;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  height: 40px;
  display: block;
  width: calc(100% - 40px);
  margin: 10px 20px 0;
}

@media screen and (min-width: 913px) {
  .backArrow {
    left: calc(100% / 2 - 210px);
  }
  .checkVibeBtn {
    width: 400px;
  }
  .loginPopup {
    width: 380px;
  }
}
