.bookExperienceContainer {
	padding: 0 10px;
	background: #FFF;
	position: relative;
	min-height: 100%;
}
.pageHeading {
  	color: #000;
  	text-align: center;
  	font-family: var(--font-chewy);
  	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 1px;
	margin: 0;
	padding: 22px;
}
.backIcon {
	border-radius: 25px;
	background: #FFEA00;
	width: 50px;
	height: 50px;
	display: inline-block;
	margin-top: 10px;
	margin-left: 10px;
	position: fixed;
	top: 0;
	z-index: 3;
	left: 0;
}
.experienceImg {
	border-radius: 15px;
	margin: 0 10px 10px;
	width: calc(100% - 20px);
}
.topSection h2 {
	color: #1C1C1E;
	text-align: center;
	font-family: var(--font-inter);
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	margin: 12px 0 0;
}
.topSection p {
	color: #1C1C1E;
	text-align: center;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 24px;
	margin: 5px 0 20px;
}
.sectionHeading {
	color: #1C1C1E;
	font-family: var(--font-poppins);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	border-bottom: 2.5px solid #FFEA00;
	margin: 0;
	display: inline-block;
	padding-bottom: 2px;
}
.characteristics h4 {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 0 0 0 10px;
}
.characteristiclist {
	padding: 0 10px 0 28px;
	margin: 5px 0 10px;
}
.characteristiclist li {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 13px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
}
.packageWrap {
	margin: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
   -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    cursor: pointer;
}
.packageInfo {
    border-radius: 10px 0 0 10px;
    width: calc(60% - 22px);
    float: left;
    padding: 10px;
    text-align: left;
    border-left: 1px solid #362C23;
    border-top: 1px solid #362C23;
    border-bottom: 1px solid #362C23;
    border-right: 1px solid transparent;
    position: relative;
}
.packageInfo h5 {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin: 0;
}
.packageInfo label {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}
.packageInfo p {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 12px;
	margin: 0;
}
.selectedpackageWrap {
    background: rgba(255, 234, 0, 0.80);
}
.selectedpackageWrap .packageInfo {
	border: none;
	padding: 11px;
}
.packageImgWrap {
	width: 40%;
	float: right;
	background-size: contain;
}
.counts {
	margin-top: 10px;
	text-align: center;
	position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
}
.minus svg,
.disabledMinus svg,
.plus svg {
	margin-top: 3px;
}
.minus, .plus, .disabledMinus {
	background: var(--Eerie-Black, #1C1C1E);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	text-align: center;
	display: inline-block;
	cursor: pointer;
}
.disabledMinus {
	background: #CACACE;
}
.counts span {
	font-weight: 600;
	margin: 3px 12px 0;
	min-width: 24px;
	text-align: center;
	vertical-align: top;
	color: var(--Eerie-Black, #1c1c1e);
	display: inline-block;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	line-height: 24px;
}
.recurrencesList {
	margin-top: 10px;
	padding: 0 10px;
}
.recurrencesList li {
	list-style: none;
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	margin-bottom: 10px;
	text-align: center;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
   -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
}
.recurrencesList span {
	width: calc(67% - 1px);
	display: inline-block;
	border-left: 1px solid #362C23;
	border-top: 1px solid #362C23;
	border-bottom: 1px solid #362C23;
	border-radius: 10px 0 0 10px;
	line-height: 38px;
}
.recurrencesList label {
	width: 33%;
	display: inline-block;
	background: #362C23;
	color: #fff;
	border-radius: 0 10px 10px 0;
	line-height: 40px;
}
.selectedRecurrence span {
	background: rgba(255, 234, 0, 0.80);
	border: none;
	padding: 1px 1px 1px 0;
}
.nextBtn,
.paymentBtn,
.paymentBtn2 {
	position: fixed;
    bottom: 10px;
    left: 10px;
    height: 55px;
    outline: none;
    z-index: 2;
    text-align: center;
    cursor: pointer;
    color: var(--Canary-Yellow, #FFEA00);
    font-family: var(--font-inter);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 15px;
    border: none;
    background: var(--Eerie-Black, #1C1C1E);
    width: calc(50% - 15px);
}
.nextBtn:disabled,
.paymentBtn:disabled {
	background-color: #ccc;
	color: #fff;
}
.paymentBtn,
.paymentBtn2 {
	width: calc(100% - 20px);
}
.paymentBtn2 {
	position: relative;
    width: 100%;
    left: 0;
    margin-top: 20px;
}
.callBack {
	border-radius: 15px;
	background: #FFEA00;
	position: fixed;
	bottom: 10px;
	color: #000;
	text-align: center;
	font-family: var(--font-inter);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	border: none;
	outline: none;
	right: 10px;
	height: 55px;
	width: calc(50% - 15px);
}
.depositMsg {
	overflow: hidden;
	margin-top: 40px;
	padding-bottom: 110px;
}
.depositMsg p {
    color: #000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: var(--font-poppins);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    float: right;
}
.depositMsg span {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 10px;
	font-style: normal;
	font-weight: 300;
	line-height: 15px;
	display: block;
}
.depositMsg label {
	border-radius: 10px;
	background: #FFEA00;
	color: #000;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	font-family: var(--font-poppins);
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	padding: 12px;
	float: left;
	text-align: center;
}
.bookingPage h2 {
	color: #000;
	text-align: center;
	font-family: var(--font-inter);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 1.2px;
	margin: 50px 0 24px;
}
.guestsInput {
    position: relative;
    margin: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background: #F2F2F7;
    padding: 10px 40px 18px;
}
.adults {
	margin-top: 10px;
}
.children {
	margin-top: 40px;
}
.adults label,
.children label {
    color: var(--Eerie-Black, #1C1C1E);
    display: inline-block;
    font-family: var(--font-inter);
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
}
.adultsCounts,
.childrenCounts {
    float: right;
    margin-top: -10px;
}
.minusBtn,
.plusBtn {
    background: var(--Eerie-Black, #1c1c1e);
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 42px;
    text-align: center;
    width: 42px;
}
.adultsCounts span,
.childrenCounts span {
	font-weight: 600;
    margin: 10px 10px 0;
    min-width: 24px;
    text-align: center;
    vertical-align: top;
    color: var(--Eerie-Black, #1c1c1e);
    display: inline-block;
    font-family: var(--font-inter);
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
}
.minusBtn svg,
.plusBtn svg {
	margin-top: 9px;
}
.disabledMinusBtn,
.disabledPlusBtn {
	background: #CACACE;
}
.membersList {
	padding: 0;
	margin: 0;
}
.memberDetail {
	list-style: none;
	border-radius: 15px;
	background: var(--Ghost-White, #F2F2F7);
	padding: 10px 20px;
	margin: 10px;
	position: relative;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.memberDetail h4 {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
	margin-bottom: 10px;
}
.memberForm input[type=text],
.memberForm input[type=number] {
	width: calc(100% - 20px);
	margin-bottom: 5px;
	padding: 6px 0 6px 10px;
	box-sizing: border-box;
	border: none;
	border-bottom: 1px solid #7B7B84;
	font-family: var(--font-inter);
	color: var(--Eerie-Black, #1C1C1E);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	background: var(--Ghost-White, #F2F2F7);
}
.memberForm input[type=text]::placeholder,
.memberForm input[type=number]::placeholder {
  font-weight: 300!important ;
}
.memberForm input[type=text]:focus,
.memberForm input[type=number]:focus {
  	outline: none;
}
.memberForm input[type=text].invalid,
.memberForm input[type=number].invalid {
  	border-bottom: 1px solid #e77a7a !important;
}
.memberForm input[type=text].valid,
.memberForm input[type=number].valid  {
  	color: var(--apple-black, #1C1C1E) !important;
}
.genderInput {
	margin-bottom: 5px;
}
.genderInput label {
	color: #7B7B84;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 24px;
	margin-left: 5px;
	margin-right: 5px;
	vertical-align: top;
	margin-top: 4px;
	display: inline-block;
	cursor: pointer;
}
label.selected {
	color: var(--Eerie-Black, #1C1C1E);
}
.genderInput img {
    vertical-align: top;
    margin-right: 5px;
}
.genderHeading {
	color: #7B7B84;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 24px;
	display: block;
    margin-left: 10px;
}
.agreePolicies {
	color: var(--Eerie-Black, #1C1C1E);
	text-align: center;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin-top: 24px;
	padding-bottom: 110px;
}
.agreePolicies a {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	text-decoration-line: underline;
	display: inline-block;
}
.priceDetails {
	border-radius: 15px;
	background: var(--Ghost-White, #F2F2F7);
	padding: 20px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	margin: 10px;
}
.priceDetails ul {
	padding: 0;
	margin: 0;
}
.priceDetails li {
	list-style: none;
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding-bottom: 12px;
	border-bottom: 1px solid #CACACE;
	margin-bottom: 12px;
	overflow: hidden;
}
.priceDetails p {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 7px 0 0;
}
.priceDetails span {
	float: right;
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.finalAmount {
    margin-top: 12px;
    font-weight: 600;
}
.priceNote {
	border: none !important;
	padding: 0 !important;
	margin: 0 !important;
	font-size: 12px !important;
	font-weight: 300 !important;
}
.bookingSuccess {
	padding: 10px;
}
.bookingSuccess h3 {
	color: #000;
	text-align: center;
	font-family: var(--font-inter);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 1.2px;
	margin: 0;
}
.bookingSuccess span {
	display: block;
	margin: 30px 0 10px;
	font-family: var(--font-chewy);
}
.bookingSuccess p {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 25px;
	letter-spacing: 0.7px;
}

@media screen and (min-width: 913px) {
  .nextBtn,
  .callBack {
    width: 205px !important;
    left: calc(50% - 220px);
  }
  .paymentBtn,
  .paymentBtn2 {
  	width: 440px !important;
  	left: calc(50% - 220px);
  }
  .callBack {
  	right: calc(50% - 220px);
  	left: auto;
  	cursor: pointer;
  }

  .backIcon {
    left: calc(100% / 2 - 230px);
    cursor: pointer;
  }
}

