.termsAndPolicies {
	margin-top: 35px;
	overflow: hidden;
}
.termsAndPolicies .heading {
	color: var(--apple-black);
	font-family: 'Ubuntu';
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	display: block;
}
.termsAndPolicies .policyBox {
    width: 50%;
    float: left;
    margin-bottom: 30px;
    margin-top: 20px;
}
.termsAndPolicies .policyName {
	color: #000;
	font-family: var(--font-montserrat);
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 10px;
	display: block;
}
.termsAndPolicies .policyListItem {
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
}
