.menu {
    position: absolute;
    z-index: 3;
    cursor: pointer;
    margin: 7px 7px 0 0;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.menu svg {
    display: inline-block;
    margin: 11px 10px 11px 11px;
}
.sidebarContent {
    color: #fff;
    font-family: var(--font-inter);
    padding: 22px 16px 22px 16px;
}
.sidebarHeading {
    color: #FFF;
    margin-top: 40px;
    font-family: var(--font-poppins);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 8px;
}
.sidebarContent .aboutUs {
    color: #FFF;
    font-family: var(--font-poppins);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
}
.sidebarContent .contactUsWrap {
    padding: 26px 0 40px;
}
.sidebarContent .contactUs {
    color: #FFF;
    font-family: var(--font-poppins);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    display: block;
}
.sidebarContent .instagram,
.sidebarContent .email {
    cursor: pointer;
    margin-top: 8px;
    display: inline-block;
}
.sidebarContent .email {
    margin-left: 16px;
}
.sidebarOverlayClosed {
    position: relative !important;
    display: none;
}
.closeIcon {
    float: right;
    cursor: pointer;
}
.sidebarContent .readMore {
    color: #FFF;
    font-family: var(--font-poppins);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
}
.sidebarContent .para1,
.sidebarContent .para3 {
    color: #FFF;
    font-family: var(--font-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}
.sidebarContent .para2 {
    color: #FFEA00;
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
}
@media screen and (min-width: 913px) {
    .sidebarDesktopOpen {
        right: calc(50% - 240px) !important;
    }
    .sidebarDesktopClose {
        right: calc(50% - 240px) !important;
        display: none;
    }
}
