.vibeCheck {
  position: relative;
  background: #fff;
  height: 100%;
}
.logo {
  position: relative;
  display: block;
}
.vibeCheckStep1 h2 {
  color: var(--White, #FFF);
  font-family: var(--font-inter);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin: 0;
}
.notbold {
  font-weight: 100;
}
.skipBtn {
  position: absolute;
  right: 30px;
  top: 30px;
  display: inline-block;
  padding: 10px 24px;
  border-radius: 30px;
  border: 1px solid var(--Ghost-White, #F2F2F7);
  color: var(--Ghost-White, #F2F2F7);
  text-align: center;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  display: none;
}
.nextButton svg {
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  height: 24px;
  margin-top: -2px;
}
.vibeCheckStep1,
.vibeCheckStep2 {
  background: #fff;
  padding: 30px;
}
.vibeCheckStep1 .skipBtn,
.vibeCheckStep2 .skipBtn {
  border: 1px solid var(--Eerie-Black, #1C1C1E);
  color: var(--Eerie-Black, #1C1C1E);
}
.vibeCheckStep1 h2,
.vibeCheckStep2 h2 {
  padding-top: 60px;
  color: var(--Eerie-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.vibeCheckStep1 label,
.vibeCheckStep2 label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 10px 0 30px;
  display: block;
}
.imageGrid {
  padding-bottom: 60px;
  overflow: hidden;
}
.imageTile,
.imageTileSelected {
  width: calc(50% - 5px);
  float: left;
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;
}
.imageTile img,
.imageTileSelected img {
  width: 100%;
  border-radius: 12px;
}
.checkIcon {
  width: 40px !important;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.imgLabel {
  color: var(--White, #FFF);
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  bottom: 5px;
  margin: 0;
  text-align: center;
  width: 100%;
  display: block;
}
.imageTile:nth-child(odd),
.imageTileSelected:nth-child(odd) {
  margin-right: 5px;
}
.imageTile:nth-child(even),
.imageTileSelected:nth-child(even) {
  margin-left: 5px;
}
.backArrow {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1212;
  cursor: pointer;
}
.nextButton {
  display: block;
  width: calc(100% - 60px);
  padding: 12px 0;
  border-radius: 18px;
  background: var(--Eerie-Black, #1C1C1E);
  color: var(--Ghost-White, #F2F2F7);
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  border: 2px solid var(--Ghost-White, #F2F2F7);
  position: fixed;
  bottom: 20px;
  z-index: 2;
  cursor: pointer;
}
.nextButton:disabled {
  background-color: #ccc;
}
.tourWrap {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 50px 0px;
  background: #fff;
  position: absolute;
  border-radius: 8px;
  z-index: 1;
  padding: 5px;
  left: -30px;
  width: calc(100% - 20px);
  top: calc(50% - 20px);
}
.closeIcon {
  float: right;
}
.tourWrap label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0
}
.closeIcon svg {
  width: 20px;
  height: 20px;
}
.leftArrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  position: absolute;
  left: -10px;
  top: 10px;
}

@media screen and (min-width: 913px) {
  .backArrow {
    left: calc(100% / 2 - 210px);
  }
  .nextButton {
    width: 420px;
  }
}
