.bottomFixedBar {
	position: fixed;
  width: 100%;
  background: #F2F2F7;
	bottom: 0;
	left: 0;
	z-index: 3;
  border-top: 1px solid #ccc;
}
.bottomFixedBar ul {
  padding: 0;
  margin: 0;
}
.bottomFixedBar li {
  list-style: none;
  width: 33%;
  text-align: center;
  position: relative;
  float: left;
  padding: 10px 0;
  cursor: pointer;
}
.bottomFixedBar label {
  color: #1C1C1E;
  font-family: var(--font-inter);
  font-size: 10.055px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: block;
  text-align: center;
}
.selected i {
  border-radius: 0px 0px 20.5px 20.5px;
  background: rgba(255, 234, 0, 1);
  display: inline-block;
  padding: 7px 9px;
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  margin: auto;
  width: 24px;
}
.selected label {
  margin-top: 30px;
}
@media screen and (min-width: 913px) {
	.bottomFixedBar {
		width: 480px;
	  left: calc(50% - 240px);
	}
}

