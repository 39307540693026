.bytes {
  background: #fff;
}
.byte {
  width: 230px;
  display: inline-block;
  border-radius: 15px;
  margin-right: 20px;
  position: relative;
  margin-top: 16px;
  z-index: 1;
  vertical-align: top;
}
.byte img {
  width: 100%;
  border-radius: 15px;
}
.byte h4 {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 10px 0 0;
  white-space: normal;
}
.byte p {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0 !important;
  white-space: normal;
}
.scrollWrap {
  padding: 40px 0 10px;
  position: relative;
  z-index: 1;
}
.scrollLine {
  height: 1px;
  border-radius: 10px;
  background: #1C1C1E;
  display: block;
}
.scrollBar {
  height: 7px;
  border-radius: 20px;
  background: #FFEA00;
  position: absolute;
  left: 0;
  width: 35px;
  top: 37px;
}
