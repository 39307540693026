.blogContainers {
  margin-top: 35px;
  overflow: auto;
  padding-left: 20px;
  position: relative;
  white-space: nowrap;
  z-index: 2;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.blogContainers::-webkit-scrollbar {
  display: none;
}
.blog {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 200px;
  margin-right: 20px;
}
.blog img {
  border-radius: 12px;
  height: 200px;
  width: 100%;
}
.blogTitle {
  margin-top: -34px;
  position: relative;
  background: #F6F6F6;
  border-radius: 10px;
  padding: 8px 15px;
  white-space: normal;
  color: #000;
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.blogs h2 {
  color: #FFF;
  font-family: var(--bodyl);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 30px;
}
.subHeading {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 12px 16px 12px;
  display: block;
}