.editPortfolio {
	background: var(--Ghost-White, #F2F2F7);
	height: 100%;
}
.editPortfolioContainer {
	padding: 30px;
	clear: both;
	overflow: hidden;
}
.savePortfolio,
.recheckVibes,
.savePortfolioBtn,
.discardBtn {
	border-radius: 22px;
	border: 2px solid #000;
	margin: 30px 30px 0 0;
	float: right;
	padding: 5px 14px;
	color: #000;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	cursor: pointer;
}
.recheckVibes {
	border-radius: 12px;
	border: 1px solid #000;
	margin: 30px 0 50px;
	background: var(--Ghost-White, #F2F2F7);
}
.savePortfolio svg,
.savePortfolioBtn svg {
	display: inline-block;
	margin-right: 5px;
	margin-top: -4px;
	vertical-align: middle;
}
.savePortfolioBtn {
    border-radius: 18px;
    background: var(--Eerie-Black, #1C1C1E);
    color: var(--Ghost-White, #F2F2F7);
    margin: 0;
    width: 100%;
    text-align: left;
    padding-left: 30px;
    height: 48px;
}
.vibes {
	border-radius: 24px;
	background: #E4D4BF;
	position: relative;
	padding: 20px 12px;
	overflow: hidden;
	clear: both;
	margin-top: 15px;
}
.userVibesCarousel {
	width: 50%;
	height: 150px;
	float: left;
}
.vibesTitleAndDesc {
    width: calc(50% - 10px);
    float: right;
    padding-right: 10px;
}
.vibesTitleAndDesc h3 {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--bodyl);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 0 0 5px;
}
.vibesTitleAndDesc p {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}
.leftNavigation {
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
    z-index: 3;
    cursor: pointer;
}
.rightNavigation {
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    cursor: pointer;
}
.heading {
	color: #000;
	font-family: var(--bodyl);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 40px;
}
.topContainer img {
	border-radius: 50%;
	width: 160px;
	height: 160px;
	float: left;
}
.imgModifyBtns {
	float: left;
	margin-left: 30px;
	max-width: calc(100% - 190px);
	margin-top: 21px;
}
.imgModifyBtn {
	border-radius: 12px;
	background: var(--Eerie-Black, #1C1C1E);
	color: var(--Ghost-White, #F2F2F7);
	text-align: center;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	border: none;
	outline: none;
	margin-bottom: 20px;
	display: inline-block;
	padding: 7px 17px;
	cursor: pointer;
}
.editPortfolioForm {
	clear: both;
	padding-top: 40px;
}
.editPortfolioForm input[type=text],
.editPortfolioForm input[type=number],
.editPortfolioForm textarea {
	width: calc(100% - 20px);
	margin-bottom: 40px;
	padding: 6px 0 6px 10px;
	box-sizing: border-box;
	background: #fff;
	border: none;
	border-bottom: 1px solid #000 !important;
	font-family: var(--font-montserrat);
	color: var(--Eerie-Black, #1C1C1E);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	background: var(--Ghost-White, #F2F2F7);
	margin-left: 20px;
}
.editPortfolioForm textarea {
	border: 1px solid #000 !important;
	border-radius: 6px;
}
.editPortfolioForm input[type=text]:focus,
.editPortfolioForm input[type=number]:focus,
.editPortfolioForm textarea:focus {
  	outline: none;
}
.editPortfolioForm input[type=text].invalid,
.editPortfolioForm input[type=number].invalid {
  	border-bottom: 1px solid #e77a7a !important;
}
.editPortfolioForm textarea.invalid {
  	border: 1px solid #e77a7a !important;
}
.editPortfolioForm input[type=text].valid,
.editPortfolioForm input[type=number].valid,
.editPortfolioForm textarea.valid  {
  	color: var(--apple-black, #1C1C1E) !important;
}
.label {
	text-align: left;
	display: block;
	margin-bottom: 10px;
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.ageWrap input {
	border-radius: 12px !important;
	border: 1px solid #000 !important;
	width: 125px !important;
	text-align: center !important;
	padding-left: 0 !important;
}
.discardBtn {
	margin: 0;
	margin-top: 15px;
	width: 100%;
	min-height: 48px;
	padding-right: 0;
	margin-bottom: 30px;
	text-align: left;
	padding-left: 30px;
}
.discardBtn svg {
	vertical-align: bottom;
	margin-left: -7px;
	margin-right: 3px;
}
@media screen and (min-width: 913px) {
  	.editPortfolio,
  	.recheckVibes,
  	.editPortfolioContainer,
  	.editPortfolioForm input[type=text],
  	.editPortfolioForm input[type=number],
	.editPortfolioForm textarea,
	.discardBtn {
    	background: #fff;
  	}
}
