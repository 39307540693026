.wishlistDetails {
	background: var(--Ghost-White, #F2F2F7);
	padding: 30px;
	min-height: 100%;
}
.backFromWishlistContainer {
	position: fixed;
	top: 40px;
	left: 30px;
	z-index: 1212;
}
.backFromWishlistContainer .backIcon {
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 14px);
    cursor: pointer;
}
.wishlistDetails h1 {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--bodyl);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 55px;
}
.wishlistDetails img {
	border-radius: 30px;
	width: 100%;
}
.wishlistDetails p {
	color: var(--Apple-Black, #1C1C1E);
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}
.wishlistDetails label {
	color: #A5A5B1;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}
.shareWishlistContainer {
	margin-top: 40px;
	position: relative;
	padding-bottom: 75px;
	display: block;
}
.deleteFromWishlist {
	position: absolute;
	top: 25px;
	right: 30px;
	cursor: pointer;
}
@media screen and (min-width: 913px) {
	.wishlistDetails {
		background: #fff;
	}
  	.backFromWishlistContainer {
    	left: calc(100% / 2 - 210px);
  	}
}
