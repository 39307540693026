.destination {
	padding-bottom: 62px;
}
.topContainer {
	position: relative;
}
.topContainer img {
	width: 100%;
}
.locationWrap {
	position: absolute;
	width: calc(100% - 60px);
	bottom: 135px;
	left: 30px;
	right: 30px;
}
.locationWrap p {
	color: #FFF;
	font-family: var(--font-inter);
	font-size: 48px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}
.shareIcon {
    position: absolute;
    top: 16px;
    right: 67px;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
}
.details {
	border-radius: 24px 24px 0px 0px;
	background: #FFF;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
	padding: 25px 25px 25px 30px;
	margin-top: -100px;
	z-index: 1;
	position: relative;
}
.details h4 {
	color: #000;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 0 0 10px;
}
.tags span {
	border-radius: 12.641px;
	background: #fff;
	border: 1.5px solid #FFEA00;
	padding: 2px 0;
	color: #000;
	font-family: var(--font-inter);
	font-size: 12px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
	width: calc(25% - 8px);
	margin-right: 5px;
	margin-bottom: 8px;
	display: inline-block;
	text-align: center;
}
.title {
	color: #000;
	font-family: var(--font-inter);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	margin: 30px 0 10px;
	line-height: normal;
}
.description {
	color: #000;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
}
.gallery {
	position: relative;
	background: #F2F2F7;
	padding: 40px 30px;
	background: #1C1C1E;
}
.image0 {
	border-radius: 45px 5px 0 5px;
	width: calc(50% - 9px);
}
.rightImgs {
	float: right;
	width: calc(50% - 9px);
}
.image1 {
	border-radius: 45px 5px 0 5px;
	vertical-align: top;
	width: 100%;
}
.image2 {
	border-radius: 0 45px 5px 0;
	margin-top: 15px;
	width: 100%;
}
.image3 {
	border-radius: 0px 0px 5px 50px;
	width: 100%;
	margin-top: 12px;
}
.promotionCarousel {
	background-position: top 100px center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 40px 30px;
	overflow: hidden;
	margin-top: -1px;
  	margin-left: -1px;
}
.promoCarousel {
	height: 155px;
	border-radius: 28.325px 2.833px 2.833px;
	background: #FFEA00;
	position: relative;
	margin-left: 1px;
}
.promoCarousel button {
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 23px;
	background: #000;
	color: #FFF;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding: 10px 15px;
	margin-top: 10px;
}
.headingAndTitle {
  float: left;
  width: 65%;
  margin: 25px 0 0 30px;
  text-align: left;
}
.headingAndTitle h3{
  color: #000;
  font-family: var(--font-inter);
  font-size: 22.66px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.promoCarousel img {
  border-radius: 28.325px 2.833px;
  width: 50% !important;
  height: 100%;
  float: right;
  position: absolute;
  bottom: -10px;
  right: -10px;
}
.googleMap {
	background: #F2F2F7;
	background-position: bottom 0 center;
  	background-size: cover;
  	background-repeat: no-repeat;
  	padding: 10px 30px 30px;
}
.googleMap h3 {
	color: #FFF;
	font-family: var(--font-inter);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin:  0 0 25px 0;
	border-bottom: 2px solid #FFEA00;
	display: inline-block;
	padding-right: 42px;
	padding-bottom: 12px;
}
.mapImgWrap {
	padding: 7px;
	border-radius: 6px;
	background: #fff;
}
.mapImgWrap img {
	width: 100%;
	vertical-align: middle;
}
.blogs h2 {
	border-bottom: 2px solid #FFEA00;
	display: inline-block;
	padding-right: 42px;
	padding-bottom: 10px;
	font-family: var(--font-inter);
	font-size: 24px;
}


