.experienceDetails {
  background: #fff;
}
.backIcon,
.shareIcon {
  border-radius: 25px;
  background: #FFEA00;
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  top: 0;
  z-index: 3;
}
.shareIcon {
  right: 10px;
}
.backIcon {
  left: 0;
}
.pageHeading {
  color: #000;
  text-align: center;
  font-family: var(--font-chewy);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  margin: 0;
  padding: 22px;
}
.topContainerParent {
  padding-bottom: 70px;
}
.shareAndFavourite {
  position: relative;
  float: right;
  margin-top: -100px;
}
.topContainer {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top 265px center;
}
.bannerImage {
  height: 100%;
  width: 100%;
}
.title {
  color: #FFEA00;
  font-family: var(--font-montserrat);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 10px 0 10px;
}
.subtitle {
  color: #F6F6F6;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  margin: 0 10px 24px 10px;
}
.titleAndSubtitle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 60.67%, rgba(0, 0, 0, 0.59) 77.52%, #000 93.85%);
}
.locationAndDuration {
  text-align: center;
  margin: 10px 10px 0 10px;
}
.locationAndDuration .location,
.locationAndDuration .duration {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.locationAndDuration .location {
  margin-right: 12px;
}
.location svg,
.duration svg {
  margin-right: 4px;
  vertical-align: text-bottom;
}
.description {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.65px;
  padding: 20px;
  margin: 0;
}
.sectionHeading {
  color: #1C1C1E;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 2.5px solid #FFEA00;
  margin: 0 0 0 10px;
  display: inline-block;
  padding-bottom: 2px;
}
.immersionsWrap {
  margin-top: 10px;
}
.immersion {
  border-radius: 15px;
  display: inline-block;
  margin-right: 20px;
  position: relative;
  vertical-align: top;
  width: 230px;
  z-index: 1;
}
.immersionImgWrap {
  position: relative;
  border-radius: 15px;
  width: 100%;
  height: 307px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.immersionLabel {
  color: #F6F6F6;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  position: absolute;
  bottom: 10px;
  left: 12px;
}
.immersion p {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  white-space: normal;
  margin: 8px 0 0;
}
.scrollWrap {
  padding: 20px 0 20px;
  position: relative;
  z-index: 1;
  margin: 0 20px;
}
.scrollLine {
  height: 1px;
  border-radius: 10px;
  background: #1C1C1E;
  display: block;
}
.scrollBar {
  height: 7px;
  border-radius: 20px;
  background: #FFEA00;
  position: absolute;
  left: 0;
  width: 35px;
  top: 17px;
}
.snapshot p {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0.6px;
  margin: 10px 20px 20px;
}
.howToReachImg {
  margin: 10px 20px;
  border-radius: 15px;
  position: relative;
}
.howToReachImg label {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
  position: absolute;
  bottom: 4px;
  left: 8px;
}
.howToReach h4 {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 0 20px;
}
.htrlist {
  padding: 0 20px 0 38px;
  margin: 5px 0 10px;
}
.htrlist li {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.itineraryBrief {
  margin-top: 20px;
}
.itineraryBrief p {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.6px;
  margin: 4px 20px;
}
.itineraryBrief b {
  font-weight: 500;
}
.organizer {
  margin-top: 20px;
}
.experienceCreator {
  margin: 10px 10px 0 20px;
  width: 100px;
}
.brandIcon {
  border-radius: 50%;
  float: left;
  height: 100px;
  position: relative;
  width: 100%;
}
.brandIcon img {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
}
.brandName {
  clear: both;
  display: block;
  padding-top: 8px;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
}
.unitPrice {
  color: var(--Eerie-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-top: 10px;
}
.unitPrice b {
  font-weight: 600;
  font-size: 16px;
}
.bookExperienceBtn {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  margin: 0 auto;
  width: calc(100% - 20px);
  height: 55px;
  outline: none;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  color: var(--Canary-Yellow, #FFEA00);
  font-family: var(--font-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 15px;
  border: 3px solid #000;
  background: var(--Eerie-Black, #1C1C1E);
}
.videoContainer video {
  height: unset !important;
}
.shortExperienceContainer {
  border-radius: 13px;
  background: #000;
  margin: 0 15px 15px 15px;
  padding: 25px 15px;
  overflow: hidden;
}
.shortExperienceContainer .icon {
  width: 48px;
}
.titleAndLocationWrap {
  width: calc(100% - 52px);
  float: right;
}
.titleAndLocationWrap h3 {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.titleAndLocationWrap p {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 0;
}
.detailsWrap {
  margin-top: 20px;
  overflow: hidden;
}
.imgWrap {
  width: calc(60% - 20px);
  position: relative;
  float: left;
}
.imgWrap img {
  width: 100%;
  border-radius: 6px; 
}
.imgWrap label {
  color: #000;
  font-family: var(--font-inter);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 15px 0 0 15px;
  background: #FFEA00;
  padding: 7px;
  position: absolute;
  right: 0;
  bottom: 80px;
}
.basicDetails {
  width: 40%;
  float: right;
}
.vibeName {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 12.81px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
}
.vibeName b {
  color: var(--Canary, #FFEA00);
  font-weight: 300;
}
.experienceDesc {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.time {
  color: #FFF;
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.curatedBy {
  color: #FFF4F4;
  font-family: var(--font-inter);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.curatedBy label {
   color: #FFEA00;
  font-family: var(--font-inter);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal; 
}




@media screen and (min-width: 913px) {
  .experienceDetails,
  .topContainerParent {
    background: #fff;
  }
  .bookExperienceBtn {
    width: 440px !important;
  }
}
