.blogDetails {
	background: #fff;
	min-height: 100%;
}
.mediumBlogTopContainer {
	padding-top: 55px;
	background-color: rgba(255, 255, 255, 1);
}
.blogContainer {
	padding: 26px 15px;
}
.backIcon,
.shareIcon {
    border-radius: 25px;
    background: #FFEA00;
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-top: 15px;
    margin-left: 10px;
    position: absolute;
    top: 0;
    z-index: 3;
}
.shareIcon {
    right: 10px;
}
.backIcon {
	left: 0;
}
.pageHeading {
	color: #000;
	text-align: center;
	font-family: var(--font-chewy);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 1px;
	margin: 0;
}
.blogTitle {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 28px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	margin: 30px 10px 24px;
}
.vibes {
	font-family: var(--font-chewy);
	font-weight: 400;
	font-size: 14px;
	font-style: normal;
	color: #000;
	line-height: normal;
	margin: 0 10px 10px 10px;
}
.vibes span {
	font-family: var(--font-poppins);
	font-weight: 300;
}
.readTime {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	text-align: center;
	display: block;
	margin: 0;
}
.readTime i {
	font-style: normal;
    margin: 0 5px;
}
.section h1,
.otherMiniGuides h2 {
	color: #000;
	font-family: var(--font-inter);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	border-bottom: 2.5px solid #FFEA00;
	margin: 20px 0 0;
	display: inline-block;
}
.section h2 {
	color: #000;
	text-align: center;
	font-family: var(--font-inter);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 30px 0 0;
}
.section h3 {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 10px 0 0;
}
.section p {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	margin: 10px 0 0;
}
.section .list {
	list-style: none;
	margin: 5px 0 0;
	padding-left: 20px;
}
.list li {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	margin: 0;
}
.list li::before {
	content: "\2022";
	color: #1C1C1E;
	display: inline-block; 
	width: 1em;
	margin-left: -1em;
}
.section img {
	border-radius: 15px;
	width: 100%;
	margin-top: 12px;
}
.travelWithXpHive {
	height: 48px;
	border-radius: 15px;
	border: 3px solid #000;
	background: var(--Eerie-Black, #1C1C1E);
	color: var(--Canary-Yellow, #FFEA00);
	text-align: center;
	font-family: var(--font-inter);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	outline: none;
	margin: 20px;
    width: calc(100% - 40px);
    cursor: pointer;
}








