.filterSection,
.filterSectionOpen {
	background: #000;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 4;
    left: -100%;
    top: 0;
    transition: left 0.3s ease-out;
	-webkit-transition: left 0.3s ease-out;  /* For Safari and older Chrome */
	-moz-transition: left 0.3s ease-out;     /* For Firefox */
	-o-transition: left 0.3s ease-out;       /* For Opera */
	-ms-transition: left 0.3s ease-out;      /* For Internet Explorer 10 */
}
.filterSectionOpen {
	left: 0;
	transition: left 0.3s ease-out;
	-webkit-transition: left 0.3s ease-out;  /* For Safari and older Chrome */
	-moz-transition: left 0.3s ease-out;     /* For Firefox */
	-o-transition: left 0.3s ease-out;       /* For Opera */
	-ms-transition: left 0.3s ease-out;      /* For Internet Explorer 10 */
}
.header {
	margin: 18px 10px 12px;
}
.header label {
	color: #FFF;
	font-family: var(--font-poppins);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 38px;
}
.header i {
	position: absolute;
	right: 10px;
	top: 24px;
	cursor: pointer;
}
.filterNames {
	width: 100px;
	display: inline-block;
}
.filterNames label {
	color: #FFF;
	font-family: var(--font-poppins);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 38px;
	display: block;
	margin: 10px 0;
	padding: 0 10px;
	cursor: pointer;
}
.selectedTab {
	color: #000 !important;
	border-radius: 0px 15px 15px 0px;
	background: #FFEA00;
}
.filterValues {
	width: calc(100% - 130px);
    margin-left: 20px;
    display: inline-block;
    float: right;
    vertical-align: top;
    margin-top: 12px;
    margin-right: 10px;
    overflow-y: scroll;
    height: calc(100vh - 150px);
}
.filterValues label {
	display: block;
	color: #FFF;
	font-family: var(--font-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 38px;
    white-space: break-spaces;
    cursor: pointer;
}
.filterValues i {
	vertical-align: text-top;
    margin-top: -2px;
    display: inline-block;
}
.btnsWrap {
	position: absolute;
	bottom: 20px;
	width: 100%;
	text-align: center;
}
.clearBtn {
	color: #FFF;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 150% */
	text-decoration-line: underline;
	text-decoration-style: dotted;
	text-decoration-skip-ink: none;
	text-decoration-thickness: 20%; /* 3.2px */
	text-underline-offset: auto;
	text-underline-position: from-font;
	outline: none;
	border: none;
	width: 120px;
	height: 48px;
	background: #000;
	cursor: pointer;
}
.applyBtn {
	border-radius: 18px;
	border: 2px solid #F2F2F7;
	background: #FFEA00;
	color: #000;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	outline: none;
	width: 120px;
	height: 48px;
	margin-left: 40px;
	cursor: pointer;
}



@media screen and (min-width: 913px) {
  .filterSection,
  .filterSectionOpen {
		width: 480px !important;
		transition: none;
  }
  .filterSectionOpen {
		left: calc(50% - 240px) !important;
		transition: none;
  }
}




