.container {
  background: #000;
  position: fixed;
  padding: 18px;
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  z-index: 12212;
  left: 0;
  text-align: left;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.container::-webkit-scrollbar {
  display: none;
}
.container h3 {
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  margin: 24px 0 0;
}
.container p {
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0 0 12px 0;
}
.container b {
  font-weight: 500;
}
.downArrow {  
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.nextButton {
  border-radius: 18px;
  border: 2px solid var(--Ghost-White, #F2F2F7);
  background: var(--Eerie-Black, #1C1C1E);
  outline: none;
  color: var(--Ghost-White, #F2F2F7);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  position: fixed;
  bottom: 18px;
  width: calc(100% - 76px);
  left: 38px;
  height: 52px;
}
.nextButton svg {
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}
.formFilled {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  left: 18px;
  right: 18px;
}
.imageGrid {
  padding-bottom: 60px;
  overflow: hidden;
}
.desImageGrid {
  overflow: hidden;
}
.imageTile,
.imageTileSelected {
  float: left;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}
.imageTile img,
.imageTileSelected img {
  width: 100%;
}
.checkIcon {
  width: 40px !important;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.imageTile:nth-child(odd),
.imageTileSelected:nth-child(odd) {
  margin-right: 5px;
}
.imageTile:nth-child(even),
.imageTileSelected:nth-child(even) {
  margin-left: 5px;
}
.tourWrap {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 50px 0px;
  background: #fff;
  position: absolute;
  border-radius: 8px;
  z-index: 1;
  padding: 5px;
  left: -30px;
  width: calc(100% - 20px);
  top: calc(50% - 20px);
}
.closeIcon {
  float: right;
}
.tourWrap label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0
}
.closeIcon svg {
  width: 20px;
  height: 20px;
}
.leftArrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  position: absolute;
  left: -10px;
  top: 10px;
}
.choice,
.choiceSelected {
  padding: 4px 8px;
  display: inline-block;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background: #FFF;
  margin-bottom: 7px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
}
.choiceSelected {
  background: #FFEA00;
}
.label {
  color: #fff;
  font-family: var(--bodyl);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.formContainer input[type=text],
.formContainer input[type=number],
.formContainer input[type=email],
.formContainer textarea {
  width: 100%;
  padding: 3px 0 3px 10px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border: 1px solid #000 !important;
  border-radius: 10px;
  color: rgba(28, 28, 30, 0.50);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.formContainer input[type=text]:focus,
.formContainer input[type=number]:focus,
.formContainer input[type=email]:focus,
.formContainer textarea:focus {
  outline: none;
}
.formContainer input[type=text].invalid,
.formContainer input[type=number].invalid,
.formContainer input[type=email].invalid {
  border: 1px solid #e77a7a !important;
}
.formContainer textarea.invalid {
  border: 1px solid #e77a7a !important;
}
.formContainer input[type=text].valid,
.formContainer input[type=number].valid,
.formContainer input[type=email].valid,
.formContainer textarea.valid  {
  color: var(--apple-black, #1C1C1E) !important;
}
.tabs {
  margin: 10px 0;
}
.tabs .tab,
.tabs .tabSelected {
  border-radius: 10px;
  background: #FFF;
  text-align: center;
  padding: 0 10px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 7px;
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  border: 2px solid #fff;
  cursor: pointer;
}
.tabs img,
.tabSelected img {
  margin-right: 4px;
  vertical-align: middle;
}
.tabSelected {
  border: 2px solid #FFF !important;
  background: #FFEA00 !important;
}
.backArrow {
  position: absolute;
  left: 8px;
  top: 10px;
}
.popularInterests {
  margin-top: 24px;
  text-align: center;
}
.interests {
  text-align: left;
  margin-top: 12px;
}
.popularInterests h3 {
  color: #FFF;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
}
.interests h3 {
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 156.25% */
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 7.5%; /* 1.2px */
  text-underline-offset: auto;
  text-underline-position: from-font;
  margin: 0;
}
.interestsContainer {
  padding-bottom: 70px;
}
.showAllLess {
  margin-top: 20px;
  color: #FFF;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 178.571% */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 7.5%; /* 1.05px */
  text-underline-offset: auto;
  text-underline-position: from-font;
  cursor: pointer;
}
.showAllLess img {
  display: block;
  margin: 0 auto;
  margin-bottom: -12px;
}
.destinationName {
color: #FFF;
text-align: justify;
font-family: var(--font-poppins);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.destinationDesc {
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: block;
}
.formFilledPara {
  color: #FFF;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 20px 0 30px;
  display: block;
}
.shareBtn {
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  background: #FFF;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  padding: 4px 8px;
  display: block;
  margin: 0 auto;
}
.shareBtn img {
  vertical-align: middle;
  margin-right: 4px;
}
.shareAndHelp {
  color: #FFF;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 20px;
}
.whatsappWrap {
  color: #FFF;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.whatsappWrap a {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-radius: 10px;
  background: #FFF;
  padding: 4px 8px;
  text-decoration: none;
  line-height: 28px;
  display: block;
  margin: 5px auto 0;
  width: 185px;
}
.whatsappWrap img {
  width: 30px;
  margin-right: 5px;
  vertical-align: middle;
}
.tabs h5 {
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0 0 5px 0;
}
.cancleCircle {
  margin: -1px -5px 0 5px !important;
}
.suggestedDates {
  text-align: center;
}
.suggestedDate,
.suggestedDateSelected {
  border-radius: 10px;
  background: #FFF;
  padding: 10px 15px;
  border: 2px solid #FFF;
  text-align: center;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
}
.suggestedDateSelected {
  background: #FFEA00;
}
.suggestedDate h6,
.suggestedDateSelected h6 {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  margin: 0;
}
.suggestedDate label,
.suggestedDateSelected label {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  margin-top: 5px;
}
.customDates {
  border-radius: 10px;
  background: #FFF;
  padding: 10px;
  margin: 0 auto 5px auto;
  width: 260px;
  cursor: pointer;
}
.customDates label {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  cursor: pointer;
}
.customDates .arrow {
  width: 34px;
  vertical-align: middle;
}

@media screen and (min-width: 913px) {
  .container {
    width: 445px;
    left: calc(50% - 240px);
  }
  .nextButton {
    width: 400px;
    left: calc(50% - 200px);
    cursor: pointer;
  }
}








