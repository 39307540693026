.communityDetails {
	background: #fff;
	padding-bottom: 30px;
	min-height: 100%;
}
.backIcon,
.shareIcon {
    border-radius: 25px;
    background: #FFEA00;
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-top: 15px;
    margin-left: 7px;
    position: absolute;
    top: 0;
}
.shareIcon {
    right: 7px;
}
.backIcon {
	left: 0;
}
.communityInfo {
	padding: 20px;
}
.communityImg {
	width: 100%;
}
.communityTitle {
	color: #000;
	text-align: center;
	font-family: var(--font-inter);
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
}
.groupInfo {
	color: #000;
	text-align: center;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.groupInfo span {
    margin-left: 12px;
}
.description {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.description span {
	color: #000;
	font-family: var(--font-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	display: block;
	margin-bottom: 10px;
	margin-top: 20px;
}
