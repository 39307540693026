.homePage {
  background: #FFF;
  min-height: 100%;
}
.logo {
  display: inline-block;
  margin-left: -10px;
  margin-top: -10px;
}
.topSection {
  position: sticky;
  top: 0;
  padding: 30px 30px 80px 30px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('https://www.xphive.com/home-bg-img-v4.png') center/cover no-repeat lightgray;
}
.featuredExperiences {
  padding-bottom: 10px;
  position: relative;
  background: #fff;
}
.bordersTop {
  border-radius: 30px 30px 0 0;
  background: #fff;
  position: absolute;
  top: -20px;
  height: 21px;
  width: 100%;
  display: block;
}
.blogs {
  margin-top: 16px;
}
.blogs h2 {
  display: inline-block;
  line-height: 38px !important;
  color: #1C1C1E !important;
  margin-left: 12px !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  font-family: var(--font-poppins) !important;
}
.pageHeading {
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: 30px; /* 107.143% */
  margin: 20px 0 0;
}
.pageSubHeading {
  color: #FFF;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
  margin-bottom: 20px;
  margin-top: 5px;
}
.pageSubHeading span {
  color: #FFEA00;
}
.tabs {
  text-align: center;
  position: relative;
  background: #fff;
}
.tab,
.selectedTab {
  text-align: left;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 16px;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  display: inline-block;
  cursor: pointer;
}
.tab:first-child,
.selectedTab:first-child {
  text-align: right;
}
.tabs span {
  border-right: 1px solid #000;
  margin: 0 10px;
  height: 38px;
  display: inline-block;
  vertical-align: bottom;
}
.tab i,
.selectedTab i {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
}
.selectedTab {
  border-radius: 10px;
  background: rgba(255, 234, 0, 0.90);
}
.categories {
  padding-left: 12px;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.categories::-webkit-scrollbar {
  display: none;
}
.categories .category,
.categories .selectedCategory {
  border-radius: 10px;
  border: 1px solid #000;
  margin-right: 8px;
  text-align: center;
  display: inline-block;
  padding: 3px 10px;
  cursor: pointer;
}
.categories .category:last-child,
.categories .selectedCategory:last-child {
  margin-right: 12px;
}
.selectedCategory {
  border: 1px solid rgba(255, 234, 0, 0.90) !important;
  background: rgba(255, 234, 0, 0.90);
}
.categories .label {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  cursor: pointer;
  vertical-align: top;
  margin-left: 5px;
  display: inline-block;
  margin-top: 1px;
}
.selectedCategory .label {
  font-weight: 600;
}
.categories img {
  width: 24px;
  height: 24px;
}
.sticky {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 52px;
  z-index: 3;
  background-color: #fff;
  padding-bottom: 10px;
  padding-top: 5px;
}
.stickyTabs {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #fff;
  padding-bottom: 8px;
  padding-top: 8px;
}
.groupsHeading {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
  margin: 0 20px;
}
.groupsHeading2 {
  color: #000;
  text-align: left;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  padding: 10px 0 8px 12px;
  margin: 0;
}
.groupsSubHeading {
  color: #000;
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 3px 20px 20px 20px;
}
.featuredCarouselItem {
  width: 80%;
  margin: 0 auto;
}
.hives,
.experiences {
  background: #fff;
  position: relative;
}
.group {
  list-style: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 16px;
}
.group:first-child {
  margin-top: 0;
}
.hives .group {
  width: 300px;
}
.group img {
  border-radius: 15px;
  width: 100%;
  aspect-ratio: 3/4;
}
.groupsData {
  padding: 0 20px;
  margin: -10px 0 16px 0;
}
.group h3,
.featuredCarouselItem h3 {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-wrap: wrap;
}
.group .description {
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  margin: 2px 0;
  white-space: normal;
}
.duration,
.idealFor {
  border-radius: 5px;
  background: rgba(255, 234, 0, 0.90);
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 2px 5px;
  position: absolute;
  right: 10px;
  bottom: 13px;
  cursor: pointer;
}
.idealFor {
  left: 10px;
  right: auto;
}
.curator {
  text-wrap: wrap;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.location {
  text-wrap: wrap;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0;
}
.location svg {
  margin-right: 4px;
}
.location span {
  vertical-align: super;
}
.videoContainer1 video {
  border-radius: 10px;
  width: unset !important;
}
.videoContainer2 video {
  border-radius: 15px;
  width: unset !important;
}
.noResults {
  list-style: none;
  font-size: 24px;
  padding: 20px;
  font-family: var(--font-poppins);
  color: #000;
}
.filters {
  border-radius: 10px;
  border: 1px solid #000;
  margin-right: 8px;
  text-align: center;
  display: inline-block;
  padding: 3px 10px;
  cursor: pointer;
}
.filters .downArrow {
  vertical-align: top;
  margin-top: 2px;
  display: inline-block;
}
.filters .label {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  cursor: pointer;
  vertical-align: top;
  margin-left: 5px;
  display: inline-block;
  margin-top: 1px;
}
.explorerSection {
  background: #fff;
  position: relative;
}
.searchInput {
  padding: 5px 13px;
  height: 30px;
  width: 220px;
  margin: 0 auto;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-style: normal;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  border-radius: 25px;
  background: #FFEA00;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.searchInput i {
  float: left;
}
.searchInput span {
  vertical-align: top;
  margin-left: 8px;
  margin-top: 3px;
  display: inline-block;
}
.searchInput ::-ms-input-placeholder {
  font-style: italic;
}
.searchInput ::placeholder {
  font-style: italic;
}
@media screen and (min-width: 913px) {
  .header {
    width: 480px !important;
  }
}









