.memberForm {
	margin: 25px 15px 15px;
    padding: 25px 15px;
    border-radius: 15px;
	border: 1px solid #000;
}
.memberForm h4 {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-inter);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 0 0 10px;
}
.memberForm input[type=text],
.memberForm input[type=email],
.memberForm input[type=number] {
	width: calc(100% - 20px);
	margin: 10px 0 10px;
	padding: 6px 0 6px 10px;
	box-sizing: border-box;
	border: none;
	border-bottom: 1px solid #7B7B84;
	font-family: var(--font-inter);
	color: var(--Eerie-Black, #1C1C1E);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	background: #fff;
}
.memberForm input[type=text]::placeholder,
.memberForm input[type=email]::placeholder,
.memberForm input[type=number]::placeholder {
  font-weight: 300!important ;
}
.memberForm input[type=text]:focus,
.memberForm input[type=email]:focus,
.memberForm input[type=number]:focus {
  	outline: none;
}
.memberForm input[type=text].invalid,
.memberForm input[type=email].invalid,
.memberForm input[type=number].invalid {
  	border-bottom: 1px solid #e77a7a !important;
}
.memberForm input[type=text].valid,
.memberForm input[type=email].valid,
.memberForm input[type=number].valid  {
  	color: var(--apple-black, #1C1C1E) !important;
}
.genderInput {
	margin: 10px 0;
}
.genderInput label {
	color: #7B7B84;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 24px;
	margin-left: 5px;
	margin-right: 5px;
	vertical-align: top;
	margin-top: 4px;
	display: inline-block;
	cursor: pointer;
}
label.selected {
	color: var(--Eerie-Black, #1C1C1E);
}
.genderInput img {
    vertical-align: top;
    margin-right: 5px;
}
.genderHeading {
	color: #7B7B84;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 24px;
	display: block;
	margin-bottom: 15px;
    margin-left: 10px;
}
.counts {
	margin-top: 12px;
	text-align: center;
}
.minus svg,
.plus svg {
	margin-top: 9px;
}
.counts span {
	color: var(--Eerie-Black, #1C1C1E);
	text-align: center;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	margin: 0 18px;
	min-width: 24px;
	display: inline-block;
	margin-top: 10px;
    vertical-align: top;
}
.minus, .plus {
	background: var(--Eerie-Black, #1C1C1E);
	border-radius: 50%;
	width: 42px;
	height: 42px;
	text-align: center;
	display: inline-block;
	cursor: pointer;
}
.countMsg {
	color: var(--Eerie-Black, #1C1C1E);
	font-family: var(--font-inter);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin-top: 20px;
	display: block;
}
.joinBtn {
	border-radius: 20.5px;
	background: #FFEA00;
	box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
	color: #000;
	text-align: center;
	font-family: var(--font-inter);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	border: none;
	outline: none;
	height: 60px;
    width: calc(100% - 30px);
    margin: 30px 15px;
    text-align: left;
    cursor: pointer;
}
.joinBtn img {
	width: 66px;
	vertical-align: middle;
}
