.loginSignup {
	background: #060B0E;
	min-height: 100%;
}
.loginSignupImg {
  float: right;
}
.loginSignupContainer {
	clear: both;
	padding: 90px 30px 50px 30px;
	background-repeat: no-repeat;
  background-size: 100% 100%;
}
.text {
	color: var(--Eerie-Black, #1C1C1E);
	text-align: center;
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: italic;
	font-weight: 500;
	margin-top: 50px;
	line-height: normal;
}

.loginSignup input[type=email],
.loginSignup input[type=password] {
	width: 100%;
	margin-bottom: 25px;
	box-sizing: border-box;
	background: #fff;
	color: rgba(28, 28, 30, 0.50);
	font-family: var(--font-montserrat);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.688px;
	border-radius: 12px;
	border: 2px solid #000;
	padding: 16px;
}
.loginSignup input[type=email]:focus,
.loginSignup input[type=password]:focus {
  outline: none;
}
.loginSignup input[type=email].invalid,
.loginSignup input[type=password].invalid {
  border: 2px solid #e77a7a !important;
}
.loginSignup input[type=email].valid,
.loginSignup input[type=password].valid {
  color: var(--apple-black, #1C1C1E) !important;
}
.loginSignup button[type=submit] {
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 18px;
  background: var(--Apple-Black, #1C1C1E);
  color: var(--Ghost-White, #F2F2F7);
  text-align: center;
  font-family: var(--bodyl);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 50px;
}

.socialLogin {
	margin-top: 50px;
  cursor: pointer;
}
.socialLoginBtn {
  display: block;
  text-align: center;
}
.socialLoginBtn label {
  display: inline-block;
  vertical-align: top;
  margin-top: 17px;
  font-size: 22px;
  margin-left: 5px;
  cursor: pointer;
}
.dashIcon {
  vertical-align: top;
  margin-top: 17px;
  display: inline-block;
}
.headingTxt {
  color: var(--Eerie-Black, #1C1C1E);
  font-family: var(--bodyl);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 15px 0 0 0;
  position: relative;
}
.forgotPasswordBtn,
.verifyEmailBtn {
  border-radius: 12px;
  border: 2px solid var(--Eerie-Black, #1C1C1E);
  background-color: #fff;
  height: 90px;
  color: #1C1C1E;
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 100%;
  padding: 0 20px;
  margin-top: 40px;
  position: relative;
}
.forgotPasswordBtn label,
.verifyEmailBtn label {
  color: rgba(28, 28, 30, 0.50);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  display: block;
  line-height: normal;
  margin-top: 5px;
}
.forgotPasswordBtn svg,
.verifyEmailBtn svg {
  position: absolute;
  right: 20px;
  top: 31px;
}
.verifyEmailBtn {
  margin-top: 60px;
}
.goBackToLogin {
  color: var(--Ghost-White, #F2F2F7);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 12px;
  background: var(--Eerie-Black, #1C1C1E);
  outline: none;
  border: none;
  width: 100%;
  height: 56px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 25px;
}
.goBackToLogin svg {
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  margin-left: 10px;
  margin-right: 10px;
}
.linkSentTxt {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 40px 0 0;
  position: relative;
}
.resetPasswordEmailSent b {
  font-weight: 700;
}
.sendAgainTxt {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 20px 0 0;
}
.sendAgainTxt a {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
}
.goBackToLoginLabel {
  color: var(--Eerie-Black, #1C1C1E);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 80px;
  display: inline-block;
  margin-bottom: 30px;
}
.goBackToLoginLabel svg {
  vertical-align: middle;
  margin-top: -3px;
}
.verificationEmailSent,
.verifyEmail {
  position: relative;
}
.verificationEmailSent img,
.verifyEmail img {
  position: absolute;
  left: -30px;
  top: -40px;
}
