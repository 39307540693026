.communityInviteContainer .heading {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.success {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.communityInviteContainer input[type=text],
.communityInviteContainer input[type=number],
.communityInviteContainer input[type=email],
.communityInviteContainer textarea {
  width: 100%;
  margin-bottom: 18px;
  padding: 6px 0 6px 10px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border: 1px solid #000 !important;
  border-radius: 10px;
  color: rgba(28, 28, 30, 0.50);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.communityInviteContainer input[type=text]:focus,
.communityInviteContainer input[type=number]:focus,
.communityInviteContainer input[type=email]:focus,
.communityInviteContainer textarea:focus {
  outline: none;
}
.communityInviteContainer input[type=text].invalid,
.communityInviteContainer input[type=number].invalid,
.communityInviteContainer input[type=email].invalid {
  border: 1px solid #e77a7a !important;
}
.communityInviteContainer textarea.invalid {
  border: 1px solid #e77a7a !important;
}
.communityInviteContainer input[type=text].valid,
.communityInviteContainer input[type=number].valid,
.communityInviteContainer input[type=email].valid,
.communityInviteContainer textarea.valid  {
  color: var(--apple-black, #1C1C1E) !important;
}
.communityInviteContainer button[type=submit] {
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 15px;
  background: var(--Apple-Black, #1C1C1E);
  color: #FFEA00;
  text-align: center;
  font-family: var(--font-inter);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 48px;
  margin-top: 22px;
}
.label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--bodyl);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.choice,
.choiceSelected {
  padding: 8px;
  display: inline-block;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #000;
  background: #FFF;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.choiceSelected {
  background: #FFEA00;
}
@media screen and (min-width: 913px) {
  html {
    width: 480px;
    margin: 0 auto;
    position: relative;
  }
}
