.becomeCreators {
  border-radius: 18px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 170px;
  text-align: center;
  float: right;
}
.becomeCreatorsContainer .becomeCreatorsModalHeading {
  font-size: 24px;
  color: var(--apple-black, #1C1C1E);
  font-family: var(--bodyl);
  margin-bottom: 24px;
  font-weight: 700;
  text-align: center;
}
.becomeCreatorsModalHeadingSuccess {
  color: #000;
  text-align: center;
  font-family: 'Taprom';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.becomeCreatorsContainer input[type=text],
.becomeCreatorsContainer input[type=number],
.becomeCreatorsContainer input[type=email],
.becomeCreatorsContainer textarea {
  width: 100%;
  margin-bottom: 18px;
  padding: 6px 0 6px 10px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border-bottom: 1px solid #000 !important;
  color: rgba(28, 28, 30, 0.50);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.688px;
}
.becomeCreatorsContainer textarea {
  border: 1px solid #000 !important;
  border-radius: 6px;
}
.becomeCreatorsContainer input[type=text]:focus,
.becomeCreatorsContainer input[type=number]:focus,
.becomeCreatorsContainer input[type=email]:focus,
.becomeCreatorsContainer textarea:focus {
  outline: none;
}
.becomeCreatorsContainer input[type=text].invalid,
.becomeCreatorsContainer input[type=number].invalid,
.becomeCreatorsContainer input[type=email].invalid {
  border-bottom: 1px solid #e77a7a !important;
}
.becomeCreatorsContainer textarea.invalid {
  border: 1px solid #e77a7a !important;
}
.becomeCreatorsContainer input[type=text].valid,
.becomeCreatorsContainer input[type=number].valid,
.becomeCreatorsContainer input[type=email].valid,
.becomeCreatorsContainer textarea.valid  {
  color: var(--apple-black, #1C1C1E) !important;
}
.becomeCreatorsContainer button[type=submit],
.closeBtn {
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
  background: var(--Apple-Black, #1C1C1E);
  color: #fff;
  text-align: center;
  font-family: var(--bodyl);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 40px;
  margin-top: 22px;
}
.becomeCreatorsContainer button[type=submit] {
  width: 60%;
}
.closeBtn {
  width: 30%;
  margin-left: 10%;
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.688px
}
.becomeCreatorBtn {
  color: var(--Apple-White, #F2F2F7);
  font-family: var(--bodyl);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  bottom: 120px;
  right: 30px;
  text-align: left;
  cursor: pointer;
}
.becomeCreatorBtn svg {
  margin-left: 20px;
}
.label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--bodyl);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.contactInfoLabel {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--bodyl);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  display: block;
}
.creatorsForm {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.creatorsForm::-webkit-scrollbar {
  display: none;
}
.scrollWrap {
  position: absolute;
  right: 20px;
  height: calc(100% - 225px);
}
.scrollLine { 
  background: var(--Apple-Black, #1C1C1E);
  width: 1px;
  height: 100%;
  position: absolute;
  right: 10px;
  border-radius: 10px;
}
.scrollBar {
  height: 37px;
  border-radius: 20px;
  position: absolute;
  right: 7px;
  width: 8px;
  background: var(--Apple-Black, #1C1C1E);
}
.formSuccessImg {
  max-width: 320px;
  width: 100%;
}
@media screen and (min-width: 913px) {
  html {
    width: 480px;
    margin: 0 auto;
    position: relative;
  }
}