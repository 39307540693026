.getInTouchContainer {
  background: #fff;
  min-height: 100%;
  padding: 0 20px 20px;
}
.pageHeading {
  color: #000;
  text-align: center;
  font-family: var(--font-chewy);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  margin: 0;
  padding: 22px;
  margin-bottom: 20px;
}
.backIcon {
  border-radius: 25px;
  background: #FFEA00;
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
  position: fixed;
  top: 0;
  z-index: 3;
  left: 0;
}
.success {
  color: #000;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.getInTouchContainer input[type=text],
.getInTouchContainer input[type=number],
.getInTouchContainer input[type=email],
.getInTouchContainer textarea {
  width: 100%;
  margin-bottom: 18px;
  padding: 6px 0 6px 10px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border: 1px solid #000 !important;
  border-radius: 10px;
  color: rgba(28, 28, 30, 0.50);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.getInTouchContainer input[type=text]:focus,
.getInTouchContainer input[type=number]:focus,
.getInTouchContainer input[type=email]:focus,
.getInTouchContainer textarea:focus {
  outline: none;
}
.getInTouchContainer input[type=text].invalid,
.getInTouchContainer input[type=number].invalid,
.getInTouchContainer input[type=email].invalid {
  border: 1px solid #e77a7a !important;
}
.getInTouchContainer textarea.invalid {
  border: 1px solid #e77a7a !important;
}
.getInTouchContainer input[type=text].valid,
.getInTouchContainer input[type=number].valid,
.getInTouchContainer input[type=email].valid,
.getInTouchContainer textarea.valid  {
  color: var(--apple-black, #1C1C1E) !important;
}
.getInTouchContainer button[type=submit] {
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 15px;
  background: var(--Apple-Black, #1C1C1E);
  color: #FFEA00;
  text-align: center;
  font-family: var(--font-inter);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 48px;
  margin-top: 22px;
}
.label {
  color: var(--Apple-Black, #1C1C1E);
  font-family: var(--bodyl);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.choice,
.choiceSelected {
  padding: 8px;
  display: inline-block;
  color: #000;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #000;
  background: #FFF;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.choiceSelected {
  background: #FFEA00;
}
.getInTouchContainer p {
  color: var(--Eerie-Black, #1C1C1E);
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
.getInTouchContainer a {
  text-decoration: underline;
  color: #1C1C1E;
}
@media screen and (min-width: 913px) {
  html {
    width: 480px;
    margin: 0 auto;
    position: relative;
  }

  .backIcon {
    left: calc(100% / 2 - 230px);
  }
}
